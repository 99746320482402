import { keyBy, uniqBy } from 'lodash'
import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import { useMemo } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import BarChartStackedMulti from '../../components/BarChartStackedMulti'
import Select from '../../components/Select'
import Spinner from '../../components/Spinner'
import { OPTIONS_GROUP_PPR } from '../../consts'
import { useDomain } from '../../Domain'
import { usePPR } from '../../hooks/ppr'
import { LimitedChipsContainer } from '../Search/Search'
import LayoutPPR from './LayoutPPR'
import PreviewMode from '../../components/PreviewMode'

const ALL_COUNTRIES_VALUES = ['']

export default function PPRTrendGroup() {
  const domain = useDomain()
  const navigate = useNavigate()
  const { group } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const qsParams = {
    countries: searchParams.getAll('countries') ?? [],
    preview: searchParams.get('preview') ?? '',
  }
  const apiParams = useMemoCompare({
    ...qsParams,
    group,
  })

  const uiCountries =
    qsParams.countries.length > 0 ? qsParams.countries : ALL_COUNTRIES_VALUES

  const [{ data, dataParams, loading }] = usePPR(apiParams)

  const countriesByCode = useMemo(
    () => keyBy(domain.countries_data, 'code'),
    [domain.countries_data]
  )

  const countriesToUse = useMemo(() => {
    if (!data || data.length === 0) {
      return []
    }
    const distinctCountries = uniqBy(data[0].countries, 'country').map((c) => ({
      value: c.country,
      label: countriesByCode[c.country].name,
    }))
    return distinctCountries
  }, [countriesByCode, data])

  const dataToUse = useMemo(() => {
    if (data && data.length > 0) {
      return data[0].countries.filter((c) =>
        apiParams.countries.length > 0
          ? apiParams.countries.includes(c.country)
          : c
      )
    }
    return []
  }, [apiParams.countries, data])

  return (
    <LayoutPPR
      filters={
        <>
          <Select
            onChange={(e) => {
              navigate({
                pathname: `../trend/${e.value}`,
              })
            }}
            className="mb-3"
            value={group}
            label={'Classification'}
            options={OPTIONS_GROUP_PPR}
            defaultValue={'fcas'}
          />
          <Select
            onChange={(e) => {
              if (
                dataParams.countries.length !== 0 &&
                e.filter((d) => d.value === '').length > 0
              ) {
                setSearchParams({
                  ...qsParams,
                  countries: [],
                })
              } else {
                setSearchParams({
                  ...qsParams,
                  countries: e.map((c) => c.value).filter(Boolean),
                })
              }
            }}
            id="Countries"
            placeholder={'All countries'}
            closeMenuOnSelect={false}
            components={
              dataParams &&
              dataParams.countries &&
              dataParams.countries.length > 0 && {
                MultiValue: LimitedChipsContainer,
              }
            }
            isMulti
            value={uiCountries}
            isDisabled={!data}
            options={[{ value: '', label: 'All countries' }].concat(
              countriesToUse
            )}
            label={'Countries comparison'}
          />
        </>
      }
    >
      <div className="ps-5 mb-1 percentage-info">
        Percentages may not add up due to rounding.
      </div>
      <div className="h-100 overflow-y-scroll ps-5 pe-5 pb-5">
        {!data && loading && (
          <div className="h-100 w-100 d-flex align-items-center justify-content-center">
            <Spinner />
          </div>
        )}
        {data &&
          dataToUse.map((countryData, k) => (
            <Link
              to={countryData.country+'?preview='+qsParams.preview}
              className="no-link"
              key={countryData.country}
            >
              <div className="w-100" style={{ height: 300 }}>
                <BarChartStackedMulti
                  years={domain.years}
                  title={countriesByCode[countryData.country].name}
                  showYearsAxis={k === 0}
                  group={countryData.group}
                  country={countryData.country}
                  showYearsTicks={true}
                  syncId={'asian'}
                  cardBackground={true}
                  data={countryData.years}
                />
              </div>
            </Link>
          ))}
        {/* <div className="bg-scroll"></div> */}
      </div>
      {qsParams.preview && <PreviewMode />}
    </LayoutPPR>
  )
}
