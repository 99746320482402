import { useRef, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import Select from '../../components/Select'
import { usePortfolioInfoTrend } from '../../hooks/portfolioInfo'
import LayoutPortfolioInfo from './LayoutPortofolioInfo'
import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import {
  COLOR_CLUSTER_WORD,
  LABELS_AND_COLORS_GROUP,
  MODALITY_ORDERS,
  OPTIONS_CLUSTER,
  SECTORS_ORDER,
  FUND_ORDERS,
} from '../../consts'
import ProductsAreaChart from '../../components/ProductsAreaChart'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import { SlimYearsAxis } from '../../components/ProductsAreaChart/ProductsAreaChart'
import { useDomain } from '../../Domain'
import { orderBy } from 'lodash'
import Spinner from '../../components/Spinner'
// import AutoTipModal from '../../components/AutoTipModal'
// import imageSource from './assets/tip-trend.svg'
import classNames from 'classnames'
import mapOrder from '../../utils'
import PreviewMode from '../../components/PreviewMode'

SwiperCore.use([Navigation])

export default function TrendIndex() {
  const domain = useDomain()
  const [searchParams, setSearchParams] = useSearchParams()
  const apiParams = useMemoCompare({
    cluster: searchParams.get('cluster') ?? '',
    preview: searchParams.get('preview') ?? '',
  })
  const [{ data, dataParams, loading }] = usePortfolioInfoTrend(apiParams)

  const swiperRef = useRef(null)

  const [slide, setSlide] = useState(0)

  return (
    <LayoutPortfolioInfo
      showSectorTooltip={apiParams.cluster === 'project__sector_code'}
      filters={
        <>
          <Select
            onChange={(e) => {
              setSearchParams({
                ...apiParams,
                cluster: e.value,
              })
            }}
            value={apiParams.cluster}
            label={'Cluster By'}
            options={OPTIONS_CLUSTER}
          />
        </>
      }
    >
      {!data && loading && (
        <div className="h-100 w-100 d-flex align-items-center justify-content-cente">
          <Spinner />
        </div>
      )}
      {data &&
        dataParams &&
        (dataParams.cluster ? (
          <div className="h-100 w-100">
            <Swiper
              ref={swiperRef}
              spaceBetween={0}
              className="h-100 w-100"
              slidesPerView={1}
              pagination={{
                dynamicBullets: true,
              }}
              onSlideChange={(e) => setSlide(e.activeIndex)}
              modules={[Pagination, Scrollbar, A11y]}
            >
              {orderBy(data, 'total_amount', 'desc').map((group, i) => (
                <SwiperSlide key={group.group}>
                  <Link
                    to={`${group.group}?cluster=${dataParams.cluster}&preview=${apiParams.preview}`}
                    className="no-link position-relative"
                  >
                    <div
                      style={{ paddingBottom: 88 }}
                      className="h-100 w-100 ps-5 pe-5 d-flex position-relative flex-column"
                    >
                      <SlimYearsAxis
                        years={domain.years}
                        syncId="asian"
                        showYearsAxis
                        marginRightExtra={7}
                      />
                      <div className="h-100 w-100 asian-card position-relative overflow-y-scroll">
                        {mapOrder(
                          group.cluster,
                          dataParams.cluster === 'product_type'
                            ? MODALITY_ORDERS
                            : dataParams.cluster === 'fund_mnemonic'
                            ? FUND_ORDERS
                            : SECTORS_ORDER,
                          dataParams.cluster === 'product_type'
                            ? 'product_type'
                            : dataParams.cluster === 'fund_mnemonic'
                            ? 'fund_mnemonic'
                            : 'project__sector_code'
                        ).map((cluster, k) => (
                          <div
                            style={{ height: 200 }}
                            key={cluster[dataParams.cluster]}
                          >
                            <ProductsAreaChart
                              years={domain.years}
                              titleFixed
                              colorStrong={`var(--color-${
                                COLOR_CLUSTER_WORD[dataParams.cluster]
                              }-solid-${cluster[dataParams.cluster]
                                .toLowerCase()
                                .replace(' ', '-')})`}
                              colorLight={`var(--color-${
                                COLOR_CLUSTER_WORD[dataParams.cluster]
                              }-light-${cluster[dataParams.cluster]
                                .toLowerCase()
                                .replace(' ', '-')})`}
                              gradient={cluster[dataParams.cluster]
                                .toLowerCase()
                                .replace(' ', '-')}
                              title={LABELS_AND_COLORS_GROUP[group.group].label}
                              showTitle={k === 0}
                              cluster={dataParams.cluster}
                              showClusterName={true}
                              showYearsTicks={false}
                              clusterName={cluster[dataParams.cluster]}
                              syncId={'asian'}
                              showBackground={false}
                              data={cluster.years}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
              <div
                id="previousButton"
                className={classNames('previousButton', {
                  'cursor-initial': slide === 0,
                })}
                onClick={(e) => {
                  e.preventDefault()
                  swiperRef.current.swiper.slidePrev()
                }}
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="29.5"
                    y="29.5"
                    width="29"
                    height="29"
                    rx="14.5"
                    transform="rotate(180 29.5 29.5)"
                    fill="#F9FAFE"
                    fillOpacity="0.3"
                  />
                  <path
                    d="M11.9379 14.9904C11.9375 14.7859 12.0087 14.5878 12.1391 14.4304L16.5141 9.18035C16.6626 9.00166 16.8761 8.88929 17.1074 8.86796C17.3388 8.84663 17.5692 8.91808 17.7479 9.0666C17.9266 9.21512 18.0389 9.42855 18.0603 9.65992C18.0816 9.89129 18.0101 10.1217 17.8616 10.3004L13.9416 14.9904L17.7216 19.6804C17.7943 19.7699 17.8486 19.8728 17.8813 19.9834C17.9141 20.0939 17.9247 20.2099 17.9125 20.3245C17.9003 20.4392 17.8655 20.5503 17.8103 20.6515C17.755 20.7526 17.6803 20.8419 17.5904 20.9141C17.5004 20.9942 17.3949 21.0549 17.2803 21.0924C17.1658 21.1298 17.0448 21.1432 16.9249 21.1317C16.805 21.1202 16.6887 21.0841 16.5834 21.0255C16.4781 20.967 16.386 20.8874 16.3129 20.7916L12.0866 15.5416C11.9768 15.3797 11.9244 15.1856 11.9379 14.9904Z"
                    fill={slide === 0 ? '#A4A9C1' : '#0D8ECA'}
                  />
                  <rect
                    x="29.5"
                    y="29.5"
                    width="29"
                    height="29"
                    rx="14.5"
                    transform="rotate(180 29.5 29.5)"
                    stroke={slide === 0 ? '#A4A9C1' : '#0D8ECA'}
                  />
                </svg>
              </div>
              <div
                id="nextButton"
                className={classNames('nextButton', {
                  'cursor-initial': slide === 2,
                })}
                onClick={(e) => {
                  e.preventDefault()
                  swiperRef.current.swiper.slideNext()
                }}
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="29.5"
                    y="29.5"
                    width="29"
                    height="29"
                    rx="14.5"
                    transform="rotate(180 29.5 29.5)"
                    fill="#F9FAFE"
                    fillOpacity="0.3"
                  />
                  <path
                    d="M18.0621 14.9904C18.0625 14.7859 17.9913 14.5878 17.8609 14.4304L13.4859 9.18035C13.3374 9.00166 13.1239 8.88929 12.8926 8.86796C12.6612 8.84663 12.4308 8.91808 12.2521 9.0666C12.0734 9.21512 11.9611 9.42855 11.9397 9.65992C11.9184 9.89129 11.9899 10.1217 12.1384 10.3004L16.0584 14.9904L12.2784 19.6804C12.2057 19.7699 12.1514 19.8728 12.1187 19.9834C12.0859 20.0939 12.0753 20.2099 12.0875 20.3245C12.0997 20.4392 12.1345 20.5503 12.1897 20.6515C12.245 20.7526 12.3197 20.8419 12.4096 20.9141C12.4996 20.9942 12.6051 21.0549 12.7197 21.0924C12.8342 21.1298 12.9552 21.1432 13.0751 21.1317C13.195 21.1202 13.3113 21.0841 13.4166 21.0255C13.5219 20.967 13.614 20.8874 13.6871 20.7916L17.9134 15.5416C18.0232 15.3797 18.0756 15.1856 18.0621 14.9904Z"
                    fill={slide === 2 ? '#A4A9C1' : '#0D8ECA'}
                  />
                  <rect
                    x="29.5"
                    y="29.5"
                    width="29"
                    height="29"
                    rx="14.5"
                    transform="rotate(180 29.5 29.5)"
                    stroke={slide === 2 ? '#A4A9C1' : '#0D8ECA'}
                  />
                </svg>
              </div>
            </Swiper>
          </div>
        ) : (
          <div className="h-100 w-100 d-flex flex-column ps-5 pe-5 pb-5">
            {orderBy(data, 'total_amount', 'desc').map((group, i) => (
              <Link
                to={group.group+'?preview='+apiParams.preview}
                className="h-100 w-100 no-link"
                key={group.group}
              >
                <ProductsAreaChart
                  years={domain.years}
                  title={LABELS_AND_COLORS_GROUP[group.group].label}
                  showYearsAxis={i === 0}
                  showYearsTicks={true}
                  syncId={'asian'}
                  colorStrong={LABELS_AND_COLORS_GROUP[group.group].color1}
                  colorLight={LABELS_AND_COLORS_GROUP[group.group].color2}
                  gradient={LABELS_AND_COLORS_GROUP[group.group].gradient}
                  cardBackground={true}
                  data={group.years}
                />
              </Link>
            ))}
          </div>
        ))}
      {/* <AutoTipModal type={'portfolio-trend'} imageSource={imageSource} /> */}
      {apiParams.preview && <PreviewMode />}
    </LayoutPortfolioInfo>
  )
}
