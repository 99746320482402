export default function TooltipSectors() {
  return (
    <div className="tooltip-sectors">
      <div className="d-flex align-items-start mb-1">
        <div
          style={{
            backgroundColor: `var(--color-sectors-solid-anr)`,
          }}
          className={'CircleSector mt-1'}
        />
        <div className="ms-2">
          Agriculture, natural resources and <br /> rural development (ANR)
        </div>
      </div>
      <div className="d-flex align-items-center mb-1">
        <div
          style={{
            backgroundColor: `var(--color-sectors-solid-edu)`,
          }}
          className={'CircleSector'}
        />
        <div className="ms-2">Education (EDU)</div>
      </div>
      <div className="d-flex align-items-center mb-1">
        <div
          style={{
            backgroundColor: `var(--color-sectors-solid-ene)`,
          }}
          className={'CircleSector'}
        />
        <div className="ms-2">Energy (ENE)</div>
      </div>
      <div className="d-flex align-items-center mb-1">
        <div
          style={{
            backgroundColor: `var(--color-sectors-solid-fin)`,
          }}
          className={'CircleSector'}
        />
        <div className="ms-2">Finance (FIN)</div>
      </div>
      <div className="d-flex align-items-center mb-1">
        <div
          style={{
            backgroundColor: `var(--color-sectors-solid-hlt)`,
          }}
          className={'CircleSector'}
        />
        <div className="ms-2">Health (HLT)</div>
      </div>
      <div className="d-flex align-items-center mb-1">
        <div
          style={{
            backgroundColor: `var(--color-sectors-solid-ind)`,
          }}
          className={'CircleSector'}
        />
        <div className="ms-2">Industry and trade (IND)</div>
      </div>
      <div className="d-flex align-items-center mb-1">
        <div
          style={{
            backgroundColor: `var(--color-sectors-solid-ict)`,
          }}
          className={'CircleSector'}
        />
        <div className="ms-2">Information and communication (ICT)</div>
      </div>
      <div className="d-flex align-items-center mb-1">
        <div
          style={{
            backgroundColor: `var(--color-sectors-solid-psm)`,
          }}
          className={'CircleSector'}
        />
        <div className="ms-2">Public sector management (PSM)</div>
      </div>
      <div className="d-flex align-items-center mb-1">
        <div
          style={{
            backgroundColor: `var(--color-sectors-solid-tra)`,
          }}
          className={'CircleSector'}
        />
        <div className="ms-2">Transport (TRA)</div>
      </div>
      <div className="d-flex align-items-start mb-1">
        <div
          style={{
            backgroundColor: `var(--color-sectors-solid-wus)`,
          }}
          className={'CircleSector mt-1'}
        />
        <div className="ms-2">
          Water and other urban infrastructures <br /> and services (WUS)
        </div>
      </div>
      <div className="d-flex align-items-center mb-1">
        <div
          style={{
            backgroundColor: `var(--color-sectors-solid-others)`,
          }}
          className={'CircleSector'}
        />
        <div className="ms-2">Others</div>
      </div>
    </div>
  )
}
