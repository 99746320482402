import {
  useMatch,
  useNavigate,
  useResolvedPath,
  useSearchParams,
} from 'react-router-dom'
import TrendSnapshotSwitch from './TrendSnapshotSwitch'

export default function AutoTSSwitch() {
  const navigate = useNavigate()
  const prefix = useResolvedPath('..')?.pathname
  const match = useMatch(`${prefix}/:type/*`)
  const type = match.params.type
  const [searchParams] = useSearchParams()

  const cluster = searchParams.get('cluster') ?? ''
  const preview = searchParams.get('preview') ?? ''

  return (
    <TrendSnapshotSwitch
      value={type}
      onChange={(type) => {
        navigate(
          `${prefix}/${type}/${match.params['*']}${
            cluster ? `?cluster=${cluster}&preview=${preview}` : `?preview=${preview}`
          }`
        )
      }}
    />
  )
}
