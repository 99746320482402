import { extent } from 'd3-array'
import { scaleLinear } from 'd3-scale'
import { groupBy, keyBy, orderBy, sumBy, uniqBy } from 'lodash'
import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import { useMemo } from 'react'
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import CardSnapshotCluster from '../../components/CardSnapshotCluster'
import CardSnapshotSingle from '../../components/CardSnapshotSingle'
import Select from '../../components/Select'
import Spinner from '../../components/Spinner'
import {
  LABELS_AND_COLORS_GROUP,
  OPTIONS_CLUSTER,
  OPTIONS_GROUP,
} from '../../consts'
import { useDomain } from '../../Domain'
import { useYearsOptions } from '../../hooks/options'
import {
  usePortfolioInfoSnapshot,
  usePortfolioInfoTrend,
} from '../../hooks/portfolioInfo'
import { LimitedChipsContainer } from '../Search/Search'
import LayoutPortfolioInfo from './LayoutPortofolioInfo'
import PreviewMode from '../../components/PreviewMode'

export function CardsGroupCluster({ data, cluster, year, preview }) {
  const domain = useDomain()
  const dataToUse = useMemo(() => {
    return groupBy(data, 'project__country')
  }, [data])

  const countriesByCode = useMemo(
    () => keyBy(domain.countries_data, 'code'),
    [domain.countries_data]
  )

  return (
    <div
      className="d-flex overflow-y-scroll pt-0 ps-5 pe-4 pb-5"
      style={{ marginTop: 20, height: 'calc(100% - 20px)' }}
    >
      {Object.keys(dataToUse).map((country) => (
        <CardSnapshotCluster
          totalAmount={sumBy(dataToUse[country], 'total_amount')}
          title={countriesByCode[country].name}
          cluster={cluster}
          className="min-width-card"
          clusterData={dataToUse[country]}
          key={country}
          year={year}
          link={`${country}?cluster=${cluster}&year=${year}&preview=${preview}`}
          numProducts={sumBy(dataToUse[country], 'num_projects')}
        />
      ))}
    </div>
  )
}

export function CardsGroup({ data, group, year, allImports, preview }) {
  const domain = useDomain()
  const domainTotalAmount = extent(allImports)
  const scaleCircle = scaleLinear()
    .domain([domainTotalAmount[0], domainTotalAmount[1]])
    .range([10, 80])

  const countriesByCode = useMemo(
    () => keyBy(domain.countries_data, 'code'),
    [domain.countries_data]
  )

  return (
    <div
      className="d-flex overflow-y-scroll pt-0 ps-5 pe-4 pb-5"
      style={{ marginTop: 20, height: 'calc(100% - 20px)' }}
    >
      {data.map((countryData, k) => (
        <CardSnapshotSingle
          totalAmount={countryData.total_amount}
          radiusCircle={scaleCircle(countryData.total_amount)}
          title={countriesByCode[countryData.project__country].name}
          className={'min-width-card'}
          year={year}
          color1={LABELS_AND_COLORS_GROUP[group].color1}
          color2={LABELS_AND_COLORS_GROUP[group].color2}
          gradient={LABELS_AND_COLORS_GROUP[group].gradient}
          key={countryData.project__country}
          link={`${countryData.project__country}?year=${year}&preview=${preview}`}
          numProducts={countryData.num_projects}
        />
      ))}
    </div>
  )
}

const ALL_COUNTRIES_VALUES = ['']

export default function SnapshotGroup() {
  const { group } = useParams()
  const navigate = useNavigate()
  const domain = useDomain()
  const [searchParams, setSearchParams] = useSearchParams()
  const qsParams = {
    cluster: searchParams.get('cluster') ?? '',
    countries: searchParams.getAll('countries') ?? [],
    year: Number(searchParams.get('year') ?? Math.max(...domain.years)),
    preview: searchParams.get('preview') ?? '',
  }
  const apiParams = useMemoCompare({
    ...qsParams,
    group,
    preview: qsParams.preview,
  })

  const uiCountries =
    qsParams.countries.length > 0 ? qsParams.countries : ALL_COUNTRIES_VALUES

  const yearsOptions = orderBy(useYearsOptions(), 'value', 'desc')
  const year = Number(searchParams.get('year') ?? Math.max(...domain.years))

  const [{ data, dataParams, loading }] = usePortfolioInfoSnapshot(
    year,
    apiParams
  )

  const countriesByCode = useMemo(
    () => keyBy(domain.countries_data, 'code'),
    [domain.countries_data]
  )

  const countriesToUse = useMemo(() => {
    if (!data || data.length === 0) {
      return []
    }
    const distinctCountries = uniqBy(data[0].countries, 'project__country').map(
      (c) => ({
        value: c.project__country,
        label: countriesByCode[c.project__country].name,
      })
    )
    return distinctCountries
  }, [countriesByCode, data])

  const dataToUse = useMemo(() => {
    if (data && data.length > 0) {
      return data[0].countries.filter((c) =>
        apiParams.countries.length > 0
          ? apiParams.countries.includes(c.project__country)
          : c
      )
    }
    return []
  }, [apiParams.countries, data])

  const fcasFilter = useMemoCompare({
    group: 'fcas',
    preview: qsParams.preview,
  })

  const sidsFilter = useMemoCompare({
    group: 'sids',
    preview: qsParams.preview,
  })

  const [{ data: fcasTrend }] = usePortfolioInfoTrend(fcasFilter)
  const [{ data: sidsTrend }] = usePortfolioInfoTrend(sidsFilter)

  const allImports = useMemo(() => {
    if (!fcasTrend || !sidsTrend) {
      return []
    }
    let all = []
    fcasTrend.map((t) =>
      t.countries.map((d) => d.years.map((c) => all.push(c.total_amount)))
    )
    sidsTrend.map((t) =>
      t.countries.map((d) => d.years.map((c) => all.push(c.total_amount)))
    )
    return all
  }, [fcasTrend, sidsTrend])

  return (
    <LayoutPortfolioInfo
      showSectorTooltip={apiParams.cluster === 'project__sector_code'}
      filters={
        <>
          <Select
            onChange={(e) => {
              setSearchParams({
                ...qsParams,
                year: e.value,
              })
            }}
            yearSelect
            className="mb-3"
            value={year}
            options={yearsOptions}
          />
          <Select
            onChange={(e) => {
              navigate({
                pathname: `../snapshot/${e.value}`,
                search: createSearchParams({ year }).toString(),
              })
            }}
            value={group}
            className="mb-3"
            label={'Classification'}
            options={OPTIONS_GROUP}
            defaultValue={'fcas'}
          />
          <Select
            onChange={(e) => {
              if (
                dataParams.countries.length !== 0 &&
                e.filter((d) => d.value === '').length > 0
              ) {
                setSearchParams({
                  ...qsParams,
                  countries: [],
                })
              } else {
                setSearchParams({
                  ...qsParams,
                  countries: e.map((c) => c.value).filter(Boolean),
                })
              }
            }}
            closeMenuOnSelect={false}
            id="Countries"
            className="mb-3"
            placeholder={'All countries'}
            components={
              dataParams &&
              dataParams.countries &&
              dataParams.countries.length > 0 && {
                MultiValue: LimitedChipsContainer,
              }
            }
            isMulti
            value={uiCountries}
            isDisabled={!data}
            options={[{ value: '', label: 'All countries' }].concat(
              countriesToUse
            )}
            label={'Countries comparison'}
          />
          <Select
            onChange={(e) => {
              setSearchParams({
                ...qsParams,
                year,
                cluster: e.value,
              })
            }}
            value={qsParams.cluster}
            label={'Cluster By'}
            options={OPTIONS_CLUSTER}
          />
        </>
      }
    >
      {!data && loading && (
        <div className="h-100 w-100 d-flex align-items-center justify-content-center">
          <Spinner />
        </div>
      )}
      {data &&
        (dataParams.cluster ? (
          <CardsGroupCluster
            group={group}
            year={year}
            cluster={dataParams.cluster}
            data={dataToUse}
            preview={qsParams.preview}
          />
        ) : (
          <CardsGroup
            year={year}
            allImports={allImports}
            group={group}
            data={dataToUse}
            preview={qsParams.preview}
          />
        ))}
        {qsParams.preview && (
          <PreviewMode />
        )}
    </LayoutPortfolioInfo>
  )
}
