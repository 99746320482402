import { useEffect, useState } from 'react'
import { useAuthActions, useAuthState } from 'use-eazy-auth'
import styles from './Login.module.css'
import bg from './assets/background-1.svg'
import { useLocation } from 'react-router-dom'

const SAML_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8000/saml2/login/'
    : '/saml2/login/'

export default function Login() {
  const { loginLoading, loginError } = useAuthState()
  const { login, clearLoginError } = useAuthActions()
  const location = useLocation()

  // Clear login error when Login component unmount
  useEffect(() => () => clearLoginError(), [clearLoginError])

  useEffect(() => {
    const qs = new URLSearchParams(location.search)
    if (qs.has('token')) {
      login({ ssoToken: qs.get('token') })
    }
    else if (!qs.has('staff')) {
      window.location.href = SAML_URL
    }
  }, [location.search, login])

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [mode, setMode] = useState('adb')

  return (
    <form
      style={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
      className=" h-100 p-2"
      onSubmit={(e) => {
        e.preventDefault()
        if (email !== '' && password !== '') {
          login({ username: email, password })
        }
      }}
    >
      <div className="h-100 d-flex align-items-center justify-content-center">
        <div className={styles.CardLogin}>
          <div className="card-body">
            <div className={styles.SignIn}>
              Sign in <span className="fw-300">to explore</span>
            </div>
            {mode !== 'adb' && (
              <div className="mb-4">
                <label className={styles.LabelLogin}>Email</label>
                <input
                  placeholder="Email"
                  name="email"
                  className={styles.LoginInput}
                  type="email"
                  value={email}
                  onChange={(e) => {
                    clearLoginError()
                    setEmail(e.target.value)
                  }}
                />
              </div>
            )}
            {mode !== 'adb' && (
              <div className="mb-3">
                <label className={styles.LabelLogin}>Password</label>
                <input
                  placeholder="Password"
                  name="password"
                  className={styles.LoginInput}
                  type="password"
                  value={password}
                  onChange={(e) => {
                    clearLoginError()
                    setPassword(e.target.value)
                  }}
                />
              </div>
            )}
            {mode !== 'adb' && (
              <div className="d-flex justify-content-center">
                <button className={styles.ButtonLogin} disabled={loginLoading}>
                  Login
                </button>
              </div>
            )}
            {mode === 'adb' && (
              <div
                className="d-flex justify-content-center"
                style={{ marginBottom: 32 }}
              >
                <a href={SAML_URL}>
                  <button
                    className={styles.ButtonLogin}
                    disabled={loginLoading}
                    style={{ pointerEvents: 'none', margin: 0 }}
                  >
                    Login with ADB Account
                  </button>
                </a>
              </div>
            )}
            {mode === 'adb' && (
              <p
                className="text-center"
                onClick={() => {
                  setMode('passwd')
                }}
              >
                <u>Click here for password login</u>
              </p>
            )}
            {mode !== 'adb' && (
              <p
                className="text-center"
                onClick={() => {
                  setMode('adb')
                }}
              >
                <u>Click here for adb federated login</u>
              </p>
            )}
            {loginError && (
              <div className="alert alert-danger mt-3">Wrong credentials</div>
            )}
          </div>
        </div>
      </div>
    </form>
  )
}
