export default function Indicative() {
  return (
    <>
      <g clipPath="url(#clip0_2164_74298)">
        <path
          d="M1034.7 194.154L-24.2173 194.154"
          stroke="#0D8ECA"
          strokeOpacity="0.6"
          strokeWidth="1.21714"
        />
        <path
          d="M1034.7 334.125L-24.2173 334.125"
          stroke="#0D8ECA"
          strokeOpacity="0.6"
          strokeWidth="1.21714"
        />
        <path
          d="M1034.7 472.88L-24.2173 472.88"
          stroke="#0D8ECA"
          strokeOpacity="0.6"
          strokeWidth="1.21714"
        />
        <g filter="url(#filter7_di_2164_74298)" className="circle-tut">
          <path
            d="M292.24 197.076C302.121 197.076 310.132 189.065 310.132 179.184C310.132 169.302 302.121 161.292 292.24 161.292C282.358 161.292 274.348 169.302 274.348 179.184C274.348 189.065 282.358 197.076 292.24 197.076Z"
            fill="url(#paint0_linear_2164_74298)"
          />
        </g>
        <g filter="url(#filter8_di_2164_74298)" className="circle-tut">
          <path
            d="M170.221 180.401C181.212 180.401 190.121 171.491 190.121 160.5C190.121 149.51 181.212 140.6 170.221 140.6C159.23 140.6 150.321 149.51 150.321 160.5C150.321 171.491 159.23 180.401 170.221 180.401Z"
            fill="#A8BB00"
          />
        </g>
        <g filter="url(#filter9_di_2164_74298)">
          <path
            d="M228.705 202.785C239.81 202.785 248.812 193.782 248.812 182.678C248.812 171.573 239.81 162.57 228.705 162.57C217.6 162.57 208.598 171.573 208.598 182.678C208.598 193.782 217.6 202.785 228.705 202.785Z"
            fill="url(#paint1_linear_2164_74298)"
          />
        </g>
        <g filter="url(#filter10_di_2164_74298)">
          <path
            d="M191.193 210.609C200.906 210.609 208.78 202.735 208.78 193.021C208.78 183.308 200.906 175.434 191.193 175.434C181.479 175.434 173.605 183.308 173.605 193.021C173.605 202.735 181.479 210.609 191.193 210.609Z"
            fill="url(#paint2_linear_2164_74298)"
          />
        </g>
        <g filter="url(#filter11_di_2164_74298)">
          <path
            d="M220.282 246.686C232.1 246.686 241.68 237.106 241.68 225.289C241.68 213.472 232.1 203.892 220.282 203.892C208.465 203.892 198.885 213.472 198.885 225.289C198.885 237.106 208.465 246.686 220.282 246.686Z"
            fill="url(#paint3_linear_2164_74298)"
          />
        </g>
        <g filter="url(#filter12_di_2164_74298)">
          <path
            d="M273.082 219.617C279.777 219.617 285.204 214.19 285.204 207.494C285.204 200.799 279.777 195.372 273.082 195.372C266.387 195.372 260.959 200.799 260.959 207.494C260.959 214.19 266.387 219.617 273.082 219.617Z"
            fill="url(#paint4_linear_2164_74298)"
          />
        </g>
        <g filter="url(#filter13_di_2164_74298)">
          <path
            d="M261.3 196.053C266.866 196.053 271.378 191.541 271.378 185.975C271.378 180.409 266.866 175.897 261.3 175.897C255.734 175.897 251.222 180.409 251.222 185.975C251.222 191.541 255.734 196.053 261.3 196.053Z"
            fill="url(#paint5_linear_2164_74298)"
          />
        </g>
        <g filter="url(#filter14_di_2164_74298)">
          <path
            d="M249.129 217.962C254.695 217.962 259.207 213.449 259.207 207.884C259.207 202.318 254.695 197.806 249.129 197.806C243.563 197.806 239.051 202.318 239.051 207.884C239.051 213.449 243.563 217.962 249.129 217.962Z"
            fill="#A8BB00"
          />
        </g>
        <g filter="url(#filter15_di_2164_74298)">
          <path
            d="M260.083 238.653C265.649 238.653 270.161 234.141 270.161 228.575C270.161 223.009 265.649 218.497 260.083 218.497C254.517 218.497 250.005 223.009 250.005 228.575C250.005 234.141 254.517 238.653 260.083 238.653Z"
            fill="url(#paint6_linear_2164_74298)"
          />
        </g>
        <g filter="url(#filter16_di_2164_74298)">
          <path
            d="M184.875 230.51C190.233 230.51 194.576 226.167 194.576 220.81C194.576 215.452 190.233 211.109 184.875 211.109C179.518 211.109 175.175 215.452 175.175 220.81C175.175 226.167 179.518 230.51 184.875 230.51Z"
            fill="url(#paint7_linear_2164_74298)"
          />
        </g>
        <g filter="url(#filter17_di_2164_74298)">
          <path
            d="M202.171 176.444C207.441 176.444 211.713 172.172 211.713 166.902C211.713 161.632 207.441 157.359 202.171 157.359C196.901 157.359 192.628 161.632 192.628 166.902C192.628 172.172 196.901 176.444 202.171 176.444Z"
            fill="url(#paint8_linear_2164_74298)"
          />
        </g>
        <g filter="url(#filter18_di_2164_74298)" className="circle-tut">
          <path
            d="M211.007 352.261C219.04 352.261 225.552 345.749 225.552 337.716C225.552 329.683 219.04 323.171 211.007 323.171C202.974 323.171 196.462 329.683 196.462 337.716C196.462 345.749 202.974 352.261 211.007 352.261Z"
            fill="url(#paint9_linear_2164_74298)"
          />
        </g>
        <g filter="url(#filter19_di_2164_74298)">
          <path
            d="M196.279 497.93C205.239 497.852 212.439 490.525 212.361 481.565C212.283 472.605 204.956 465.404 195.995 465.482C187.035 465.561 179.835 472.888 179.913 481.848C179.991 490.808 187.318 498.008 196.279 497.93Z"
            fill="url(#paint10_linear_2164_74298)"
          />
        </g>
        <g filter="url(#filter20_di_2164_74298)">
          <path
            d="M189.122 465.004C198.083 465.004 205.347 457.74 205.347 448.779C205.347 439.819 198.083 432.555 189.122 432.555C180.162 432.555 172.898 439.819 172.898 448.779C172.898 457.74 180.162 465.004 189.122 465.004Z"
            fill="url(#paint11_linear_2164_74298)"
          />
        </g>
        <g filter="url(#filter21_di_2164_74298)" className="circle-tut">
          <path
            d="M229.857 481.059C242.914 480.749 253.248 469.913 252.938 456.855C252.628 443.798 241.792 433.464 228.734 433.774C215.677 434.084 205.343 444.921 205.653 457.978C205.963 471.035 216.799 481.369 229.857 481.059Z"
            fill="#A8BB00"
          />
        </g>
        <g filter="url(#filter22_di_2164_74298)">
          <path
            d="M208.743 438.786C215.439 438.786 220.866 433.358 220.866 426.663C220.866 419.968 215.439 414.54 208.743 414.54C202.048 414.54 196.621 419.968 196.621 426.663C196.621 433.358 202.048 438.786 208.743 438.786Z"
            fill="#A8BB00"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_2164_74298"
          x="66.7578"
          y="63.1313"
          width="317.502"
          height="495.788"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.7625 0 0 0 0 0.749792 0 0 0 0 0.749792 0 0 0 0.22 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2164_74298"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2164_74298"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_2164_74298"
          x="85.9111"
          y="82.3521"
          width="279.444"
          height="458.567"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.556863 0 0 0 0 0.792157 0 0 0 0.22 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2164_74298"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2164_74298"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_2164_74298"
          x="68.8994"
          y="63.1313"
          width="317.502"
          height="495.788"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.7625 0 0 0 0 0.749792 0 0 0 0 0.749792 0 0 0 0.22 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2164_74298"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2164_74298"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_2164_74298"
          x="87.8047"
          y="82.3521"
          width="279.444"
          height="458.567"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.556863 0 0 0 0 0.792157 0 0 0 0.22 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2164_74298"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2164_74298"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_f_2164_74298"
          x="0.485558"
          y="0.0104599"
          width="452.313"
          height="623.56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="47.7865"
            result="effect1_foregroundBlur_2164_74298"
          />
        </filter>
        <filter
          id="filter5_bd_2164_74298"
          x="105.32"
          y="90"
          width="238.744"
          height="443.928"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2164_74298"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.556863 0 0 0 0 0.792157 0 0 0 0.22 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_2164_74298"
            result="effect2_dropShadow_2164_74298"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_2164_74298"
            result="shape"
          />
        </filter>
        <filter
          id="filter6_d_2164_74298"
          x="109.32"
          y="94"
          width="230.744"
          height="435.928"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.556863 0 0 0 0 0.792157 0 0 0 0.22 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2164_74298"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2164_74298"
            result="shape"
          />
        </filter>
        <filter
          id="filter7_di_2164_74298"
          x="267.045"
          y="153.989"
          width="50.3899"
          height="50.3899"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.65143" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.713726 0 0 0 0 0.788235 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2164_74298"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2164_74298"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.08571" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.713726 0 0 0 0 0.788235 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_2164_74298"
          />
        </filter>
        <filter
          id="filter8_di_2164_74298"
          x="143.018"
          y="133.297"
          width="54.4065"
          height="54.4065"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.65143" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.658824 0 0 0 0 0.733333 0 0 0 0 0 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2164_74298"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2164_74298"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.08571" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.658824 0 0 0 0 0.733333 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_2164_74298"
          />
        </filter>
        <filter
          id="filter9_di_2164_74298"
          x="201.295"
          y="155.267"
          width="54.8201"
          height="54.8201"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.65143" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.407843 0 0 0 0 0.772549 0 0 0 0 0.917647 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2164_74298"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2164_74298"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.08571" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.407843 0 0 0 0 0.772549 0 0 0 0 0.917647 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_2164_74298"
          />
        </filter>
        <filter
          id="filter10_di_2164_74298"
          x="166.302"
          y="168.131"
          width="49.781"
          height="49.781"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.65143" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.407843 0 0 0 0 0.772549 0 0 0 0 0.917647 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2164_74298"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2164_74298"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.08571" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.407843 0 0 0 0 0.772549 0 0 0 0 0.917647 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_2164_74298"
          />
        </filter>
        <filter
          id="filter11_di_2164_74298"
          x="191.582"
          y="196.589"
          width="57.4006"
          height="57.4006"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.65143" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.407843 0 0 0 0 0.772549 0 0 0 0 0.917647 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2164_74298"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2164_74298"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.08571" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.407843 0 0 0 0 0.772549 0 0 0 0 0.917647 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_2164_74298"
          />
        </filter>
        <filter
          id="filter12_di_2164_74298"
          x="253.656"
          y="188.069"
          width="38.8513"
          height="38.8513"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.65143" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.407843 0 0 0 0 0.772549 0 0 0 0 0.917647 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2164_74298"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2164_74298"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.08571" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.407843 0 0 0 0 0.772549 0 0 0 0 0.917647 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_2164_74298"
          />
        </filter>
        <filter
          id="filter13_di_2164_74298"
          x="243.919"
          y="168.594"
          width="34.7615"
          height="34.7615"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.65143" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.960784 0 0 0 0 0.498039 0 0 0 0 0.160784 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2164_74298"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2164_74298"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.08571" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.960784 0 0 0 0 0.498039 0 0 0 0 0.160784 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_2164_74298"
          />
        </filter>
        <filter
          id="filter14_di_2164_74298"
          x="231.748"
          y="190.503"
          width="34.7615"
          height="34.7615"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.65143" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.658824 0 0 0 0 0.733333 0 0 0 0 0 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2164_74298"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2164_74298"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.08571" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.658824 0 0 0 0 0.733333 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_2164_74298"
          />
        </filter>
        <filter
          id="filter15_di_2164_74298"
          x="242.702"
          y="211.194"
          width="34.7615"
          height="34.7615"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.65143" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.960784 0 0 0 0 0.498039 0 0 0 0 0.160784 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2164_74298"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2164_74298"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.08571" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.960784 0 0 0 0 0.498039 0 0 0 0 0.160784 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_2164_74298"
          />
        </filter>
        <filter
          id="filter16_di_2164_74298"
          x="167.872"
          y="203.806"
          width="34.0071"
          height="34.0071"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.65143" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.960784 0 0 0 0 0.498039 0 0 0 0 0.160784 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2164_74298"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2164_74298"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.08571" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.960784 0 0 0 0 0.498039 0 0 0 0 0.160784 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_2164_74298"
          />
        </filter>
        <filter
          id="filter17_di_2164_74298"
          x="185.326"
          y="150.057"
          width="33.6907"
          height="33.6907"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.65143" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.407843 0 0 0 0 0.772549 0 0 0 0 0.917647 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2164_74298"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2164_74298"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.08571" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.407843 0 0 0 0 0.772549 0 0 0 0 0.917647 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_2164_74298"
          />
        </filter>
        <filter
          id="filter18_di_2164_74298"
          x="189.159"
          y="315.869"
          width="43.6956"
          height="43.6956"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.65143" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.960784 0 0 0 0 0.498039 0 0 0 0 0.160784 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2164_74298"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2164_74298"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.08571" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.960784 0 0 0 0 0.498039 0 0 0 0 0.160784 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_2164_74298"
          />
        </filter>
        <filter
          id="filter19_di_2164_74298"
          x="172.61"
          y="458.179"
          width="47.0544"
          height="47.0544"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.65143" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.960784 0 0 0 0 0.498039 0 0 0 0 0.160784 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2164_74298"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2164_74298"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.08571" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.960784 0 0 0 0 0.498039 0 0 0 0 0.160784 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_2164_74298"
          />
        </filter>
        <filter
          id="filter20_di_2164_74298"
          x="165.595"
          y="425.252"
          width="47.0549"
          height="47.0549"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.65143" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.960784 0 0 0 0 0.498039 0 0 0 0 0.160784 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2164_74298"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2164_74298"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.08571" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.960784 0 0 0 0 0.498039 0 0 0 0 0.160784 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_2164_74298"
          />
        </filter>
        <filter
          id="filter21_di_2164_74298"
          x="198.344"
          y="426.465"
          width="61.9041"
          height="61.9041"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.65143" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.658824 0 0 0 0 0.733333 0 0 0 0 0 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2164_74298"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2164_74298"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.08571" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.658824 0 0 0 0 0.733333 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_2164_74298"
          />
        </filter>
        <filter
          id="filter22_di_2164_74298"
          x="189.318"
          y="407.237"
          width="38.8513"
          height="38.8513"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.65143" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.658824 0 0 0 0 0.733333 0 0 0 0 0 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2164_74298"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2164_74298"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.08571" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.658824 0 0 0 0 0.733333 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_2164_74298"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2164_74298"
          x1="276.765"
          y1="170.6"
          x2="307.714"
          y2="187.767"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0098A8" />
          <stop offset="1" stopColor="#87D0D8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2164_74298"
          x1="211.315"
          y1="173.031"
          x2="246.095"
          y2="192.324"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#68C5EA" />
          <stop offset="1" stopColor="#ABDDF1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2164_74298"
          x1="175.982"
          y1="184.584"
          x2="206.404"
          y2="201.459"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#68C5EA" />
          <stop offset="1" stopColor="#ABDDF1" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2164_74298"
          x1="201.776"
          y1="215.024"
          x2="238.788"
          y2="235.554"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#68C5EA" />
          <stop offset="1" stopColor="#ABDDF1" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2164_74298"
          x1="262.597"
          y1="201.679"
          x2="283.566"
          y2="213.31"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#68C5EA" />
          <stop offset="1" stopColor="#ABDDF1" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2164_74298"
          x1="252.584"
          y1="181.14"
          x2="270.016"
          y2="190.81"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F57F29" />
          <stop offset="1" stopColor="#F8AD77" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2164_74298"
          x1="251.367"
          y1="223.74"
          x2="268.799"
          y2="233.41"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F57F29" />
          <stop offset="1" stopColor="#F8AD77" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2164_74298"
          x1="176.486"
          y1="216.156"
          x2="193.265"
          y2="225.463"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F57F29" />
          <stop offset="1" stopColor="#F8AD77" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_2164_74298"
          x1="193.918"
          y1="162.324"
          x2="210.424"
          y2="171.48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#68C5EA" />
          <stop offset="1" stopColor="#ABDDF1" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_2164_74298"
          x1="198.427"
          y1="330.739"
          x2="223.586"
          y2="344.694"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F57F29" />
          <stop offset="1" stopColor="#F8AD77" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_2164_74298"
          x1="182.038"
          y1="474.046"
          x2="210.236"
          y2="489.367"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F57F29" />
          <stop offset="1" stopColor="#F8AD77" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_2164_74298"
          x1="175.09"
          y1="440.996"
          x2="203.154"
          y2="456.563"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F57F29" />
          <stop offset="1" stopColor="#F8AD77" />
        </linearGradient>
        <clipPath id="clip0_2164_74298">
          <rect
            width="221.52"
            height="426"
            fill="white"
            transform="translate(114.537 98)"
          />
        </clipPath>
      </defs>
    </>
  )
}
