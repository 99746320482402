import { useEffect, useRef, useState } from 'react'
import { rj, useRj, useRunRj } from 'react-rocketjump'
import styles from './Import.module.css'
import request from 'superagent'
import * as dayjs from 'dayjs'
import Spinner from '../../components/Spinner'
import { Link, useNavigate } from 'react-router-dom'
import { useLastImportDate, useRefreshImportDate } from '../../LastImport'
import MenuLayout from '../../components/MenuLayout'
import bg from './assets/background-2.svg'
import { Alert, Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { MAP_XLSX_PAGES } from '../../consts'

const ImportDataState = rj({
  name: 'ImportData',
  effectCaller: 'configured',
  effect: (token) => (file, lastQuarter) => {
    const r = request
      .post('/api/import/')
      .set('Authorization', `Bearer ${token}`)
    r.attach('file', file)
    r.field('last_quarter', lastQuarter)
    return r.then((r) => r.body)
  },
})

const PublishPreviewState = rj({
  name: 'PublishPreviewState',
  effectCaller: 'configured',
  effect: (token) => () => {
    const r = request
      .post('/api/publish-preview/')
      .set('Authorization', `Bearer ${token}`)
    return r.then((r) => r.body)
  },
})

const CheckPreviewState = rj({
  name: 'CheckPreviewState',
  effectCaller: 'configured',
  effect: (token) => () => {
    const r = request
      .get('/api/check-preview/')
      .set('Authorization', `Bearer ${token}`)
    return r.then((r) => r.body)
  },
})

const CheckImportRJ = rj({
  name: 'ImportData',
  effectCaller: 'configured',
  effect: (token) => (id) => {
    const r = request
      .get(`/api/import/${id}/status/`)
      .set('Authorization', `Bearer ${token}`)
    return r.then((r) => r.body)
  },
  takeEffect: 'exhaust',
})

export default function Import() {
  const buttonRef = useRef()
  const [lastQuarter, setLastQuarter] = useState('')

  const [{ data: importJob }, { run: runImport, clean }] =
    useRj(ImportDataState)

  const [{ data: publishJob, pending: pendingPublish }, { run: runPublish }] =
    useRj(PublishPreviewState)

  const [{ data: checkJob }, { run: runCheckJob }] = useRunRj(CheckPreviewState)

  const [{ data: result }, { run: runCheck }] = useRj(CheckImportRJ)

  const started = importJob?.id !== null && importJob?.id !== undefined
  const pending =
    started &&
    (result === null || result === undefined || result.status === 'pending')

  const error = result?.error_message
    ? 'An unexpected error occurred while importing data'
    : null
  const errorJson = error ? JSON.parse(result.error_message) : null
  //transform error to json object
  // const error = result?.error_message
  //   ? JSON.parse(result.error_message)
  //   : null

  const success = started && !pending && !error

  useEffect(() => {
    if (importJob?.id && pending) {
      const x = setInterval(() => {
        runCheck(importJob.id)
      }, 3000)
      return () => clearInterval(x)
    }
  }, [importJob?.id, pending, runCheck])

  const date = useLastImportDate()
  const refreshImportDate = useRefreshImportDate()

  useEffect(() => {
    if (success) {
      refreshImportDate()
      runCheckJob()
    }
  }, [refreshImportDate, runCheckJob, success])

  useEffect(() => {
    if (publishJob?.message) {
      runCheckJob()
    }
  }, [publishJob?.message, runCheckJob])

  const navigate = useNavigate()

  const [isOpenModal, setIsOpenModal] = useState(false)

  return (
    <div className={styles.Import} style={{ backgroundImage: `url(${bg})` }}>
      <div className="d-flex justify-content-between align-items-center position-absolute">
        <MenuLayout
          classLeft={false}
          className={'ps-4 pt-3'}
          noDate={true}
          title=""
          subtitle=""
        />
      </div>
      <div className={styles.ImportBlock}>
        <div className={styles.Title}>asian development bank</div>
        <div className={styles.Subtitle}>
          FCAS and SIDS Portfolio
          <br /> Visualization Tool
        </div>

        {!started && (
          <div className={styles.ImportBox}>
            <div>
              <input
                value={lastQuarter}
                onChange={(e) => setLastQuarter(e.target.value)}
                type={'text'}
                className={styles.ImportInput}
                style={{ width: 250 }}
                name="last_quarter"
                placeholder="Label for last quarter. Example (Q3)"
              />
            </div>
            <div
              className={styles.ImportButton}
              onClick={() => {
                buttonRef.current.click()
              }}
            >
              <span className="me-2">UPLOAD NEW DATA</span>
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.908203 9.99997C0.908203 15.2948 5.20545 19.592 10.5003 19.592C15.7951 19.592 20.0924 15.2948 20.0924 9.99997C20.0924 4.70514 15.7951 0.40789 10.5003 0.40789C5.20545 0.40789 0.908203 4.70514 0.908203 9.99997ZM11.4595 5.20393V9.04076H15.2963V10.9592H11.4595V14.796H9.54107V10.9592H5.70424V9.04076H9.54107V5.20393H11.4595Z"
                  fill="#F9FAFE"
                />
              </svg>
            </div>
            <input
              onChange={(e) => {
                const file = e.target.files[0]
                if (file) {
                  runImport(file, lastQuarter)
                }
              }}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              type={'file'}
              ref={buttonRef}
              style={{ display: 'none' }}
            />
          </div>
        )}
        {pending && (
          <div className={error ? styles.ImportBoxError : styles.ImportBox} style={{
            flexDirection: 'column',
          }}>
            <div className="spinner-import">
              <div className="double-bounce1"></div>
              <div className="double-bounce2"></div>
            </div>

            <div className='mt-2'>
              Antivirus scan in progress. Please wait. This may take a few
              seconds.
            </div>
          </div>
        )}
        {error && (
          <>
            <div className={styles.ImportBoxError}>
              {error && <div>{error ?? 'Oops, an error occurred'}</div>}
              {errorJson && (
                <div
                  className="mt-4 pointer text-decoration-underline"
                  onClick={() => {
                    setIsOpenModal(true)
                  }}
                >
                  Show error details
                </div>
              )}
            </div>
            <div
              onClick={() => {
                window.location.reload()
              }}
              className={styles.GoToWebSite}
            >
              Try again
            </div>
          </>
        )}
        {success && (
          <>
            <div className={styles.ImportBoxSuccess}>
              <span className="me-2">successfully updated </span>
              <svg
                width="14"
                height="12"
                viewBox="0 0 14 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.3263 1.07629C11.5552 0.858359 11.8599 0.737878 12.1759 0.740269C12.492 0.742661 12.7947 0.867738 13.0203 1.08911C13.2459 1.31048 13.3767 1.61082 13.385 1.92677C13.3934 2.24272 13.2786 2.54956 13.0651 2.78254L6.58132 10.8913C6.46983 11.0114 6.33527 11.1077 6.18569 11.1746C6.0361 11.2415 5.87456 11.2776 5.71073 11.2806C5.5469 11.2836 5.38414 11.2536 5.23218 11.1923C5.08022 11.131 4.94218 11.0397 4.82632 10.9238L0.526573 6.62404C0.406832 6.51246 0.310791 6.37791 0.244179 6.22841C0.177567 6.07892 0.141749 5.91753 0.138862 5.75389C0.135974 5.59025 0.166077 5.4277 0.227374 5.27594C0.28867 5.12419 0.379905 4.98633 0.495636 4.8706C0.611366 4.75487 0.749221 4.66364 0.900977 4.60234C1.05273 4.54104 1.21528 4.51094 1.37892 4.51383C1.54256 4.51672 1.70395 4.55253 1.85345 4.61915C2.00295 4.68576 2.1375 4.7818 2.24907 4.90154L5.65182 8.30266L11.2954 1.11204C11.3056 1.09953 11.3165 1.08759 11.3279 1.07629H11.3263Z"
                  fill="#F9FAFE"
                />
              </svg>
            </div>
            <Link className="no-link" to="/">
              <div className={styles.GoToWebSite}>Browse the website</div>
            </Link>
          </>
        )}
        <div className="text-center mt-5">
          <div className="mb-3 d-flex justify-content-center align-items-center">
            {checkJob && checkJob.preview && !pendingPublish && (
              <div
                style={{
                  width: '70%',
                }}
              >
                <div>
                  There is preview data available on the website. You can choose
                  to publish it or show it on the website.
                </div>
                <div className="mt-3 d-flex">
                  <Button
                    color="primary"
                    className="me-3"
                    onClick={() => {
                      navigate('/?preview=True')
                      window.location.reload()
                    }}
                  >
                    Show preview data on the website
                  </Button>
                  <Button
                    onClick={() => {
                      runPublish()
                    }}
                  >
                    Publish preview data to the website
                  </Button>
                </div>
              </div>
            )}
            {publishJob && !pendingPublish && publishJob.message && (
              <Alert
                style={{
                  width: '70%',
                }}
                color="success"
              >
                {publishJob.message}
              </Alert>
            )}
          </div>
          <span className={styles.LastUpdate}>Last Update: </span>{' '}
          <span className={styles.Date}>
            {date.timestamp ? dayjs(date.timestamp).format('MMM DD, YYYY') : ''}
          </span>
        </div>
      </div>
      <Modal
        centered
        size="lg"
        isOpen={isOpenModal}
        toggle={() => setIsOpenModal(false)}
      >
        <ModalHeader toggle={() => setIsOpenModal(false)}>
          {'File XLSX errors'}
        </ModalHeader>
        <ModalBody>
          <div>The file you are trying to upload has the following errors:</div>
          <div className="d-flex flex-column">
            {errorJson &&
              Object.keys(errorJson).map((key) => {
                return (
                  <div
                    className="mt-3"
                    key={key}
                    style={{
                      maxHeight: 400,
                      overflowY: 'scroll',
                    }}
                  >
                    <table className="table table-bordered">
                      <thead
                        style={{
                          position: 'sticky',
                          top: 0,
                        }}
                      >
                        <tr
                          style={{
                            borderBottom: 'none',
                          }}
                        >
                          <th
                            style={{
                              backgroundColor: 'var(--color-primary-solid)',
                              color: 'white',
                              borderBottom: 'none',
                            }}
                            scope="col"
                          >
                            {MAP_XLSX_PAGES[key]}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {errorJson[key].map((error) => {
                          return (
                            <tr>
                              <td>{error}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                )
              })}
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}
