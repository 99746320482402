import { Link } from 'react-router-dom'
import styles from './CardSnapshotSingle.module.css'
import { ChartCirclePack } from '../ChartCirclePack'

export const amountFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
})

export default function CardSnapshotSingle({
  numProducts,
  radiusCircle,
  className,
  totalAmount,
  color1 = 'var(--color-primary-solid)',
  color2 = 'var(--color-primary-light)',
  title,
  link,
  gradient,
  year,
}) {
  return (
    <div
      className={`${className} overflow-auto h-100 px-3 me-4 asian-card text-center`}
    >
      <Link className="no-link cursor-custom" to={link}>
        <div className={styles.Title}>{title}</div>
        <div className={styles.Subtitle}>Amount in $ million</div>
        <div className={styles.ContainerAmount}>
          <div className={styles.LabelAmount}>Portfolio amount</div>
          <div className={styles.ValueAmount}>
            <span className={styles.Unit}>$</span>
            {amountFormatter.format(totalAmount)}{' '}
          </div>
          <div className="text-center pt-2 pb-3">
            <svg width={'100%'} height={170} xmlns="http://www.w3.org/2000/svg">
              <defs>
                <linearGradient id={gradient}>
                  <stop offset="13.63%" stopColor={color1} />
                  <stop offset="86.37%" stopColor={color2} />
                </linearGradient>
              </defs>
              <circle
                fill={`url('#${gradient}')`}
                cx={'50%'}
                cy={'50%'}
                r={radiusCircle}
              >
                <animate
                  attributeName="r"
                  begin="0s"
                  dur="1s"
                  repeatCount="0"
                  from={0}
                  to={radiusCircle}
                />
              </circle>
            </svg>
          </div>
          <hr className="mt-1 mx-3" style={{ color: '#E2EDF6', opacity: 1 }} />
          <div>
            <div className={styles.LabelAmount}>Number of products</div>
            <div className={styles.ValueAmount}>{numProducts}</div>
            <ChartCirclePack
              data={[...Array(numProducts).keys()].map((d) => ({ name: d }))}
              computeColor={(d) => {
                return color1
              }}
              computeValue={(node) => 3}
              paddingCircles={2}
              dataMax={4}
              smallerRadius={3}
              dataMin={3}
              year={year}
              height={150}
              marginLeft={0}
              marginRight={0}
              marginTop={20}
              marginBottom={20}
            />
          </div>
        </div>
      </Link>
    </div>
  )
}
