import { useMemo } from 'react'
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Label,
  LabelList,
  XAxis,
} from 'recharts'
import RoundedShapeBar from '../RoundedShapeBar'

const DEFAULT_BAR_SIZE = 60
const DEFAULT_LABEL_MARGIN = 20
const DEFAULT_LABEL_WIDTH = 200
const DEFAULT_MARGIN_TOP = 20
const DEFAULT_MARGIN_BOTTOM = 5

function BarLabel({ content, percent, percentFill, description, ...props }) {
  const percentValue = `${Math.round(percent)}%`
  return (
    <g>
      <Label fontSize={16} {...props} fill={percentFill} value={percentValue} />
      {/* <Label
        fontSize={14}
        fill={'var(--color-basic-black)'}
        fontWeight={150}
        {...props}
        y={props.y + 20}
        offset={40 + (percentValue.length - 1) * 7}
        value={description}
      /> */}
    </g>
  )
}

export default function BarChartStacked({
  data,
  barSize = DEFAULT_BAR_SIZE,
  labelMargin = DEFAULT_LABEL_MARGIN,
  labelWidth = DEFAULT_LABEL_WIDTH,
  marginTop = DEFAULT_MARGIN_TOP,
  marginBottom = DEFAULT_MARGIN_BOTTOM,
  barBorderRadius,
  hideLabels = false,
  showTotal = true,
}) {
  const chartData = useMemo(() => [data], [data])
  let width = barSize
  if (!hideLabels) {
    width += labelMargin + labelWidth
  } else {
    width += 1
  }
  let marginLeft = 0
  if (!hideLabels) {
    marginLeft = -(labelMargin + labelWidth)
  }

  const deBars = [
    {
      dataKey: 'at_risk_percent',
      label: 'At risk',
      fill: "url('#gradientRed')",
      fillLabel: 'var(--color-ppr-red-solid)',
    },
    {
      dataKey: 'for_attention_percent',
      label: 'For attention',
      fill: "url('#gradientYellow')",
      fillLabel: 'var(--color-ppr-yellow-solid)',
    },
    {
      dataKey: 'on_track_percent',
      label: 'On Track',
      fill: "url('#gradientGreen')",
      fillLabel: 'var(--color-ppr-green-solid)',
    },
  ].filter((b) => data[b.dataKey] > 0)

  return (
    <ResponsiveContainer width={showTotal ? width + 100 : width} height="100%">
      <BarChart
        data={chartData}
        stackOffset="expand"
        compact
        margin={{
          top: marginTop,
          right: 0,
          left: marginLeft,
          bottom: marginBottom,
        }}
        barSize={barSize}
      >
        <defs>
          <linearGradient
            x1={0}
            x2={'50%'}
            y1={0}
            y2={'100%'}
            id="gradientGreen"
          >
            <stop offset="13.63%" stopColor={'var(--color-ppr-green-solid)'} />
            <stop offset="86.37%" stopColor={'var(--color-ppr-green-light)'} />
          </linearGradient>
          <linearGradient
            x1={0}
            x2={'50%'}
            y1={0}
            y2={'100%'}
            id="gradientYellow"
          >
            <stop offset="13.63%" stopColor={'var(--color-ppr-yellow-solid)'} />
            <stop offset="86.37%" stopColor={'var(--color-ppr-yellow-light)'} />
          </linearGradient>
          <linearGradient x1={0} x2={'50%'} y1={0} y2={'100%'} id="gradientRed">
            <stop offset="13.63%" stopColor={'var(--color-ppr-red-solid)'} />
            <stop offset="86.37%" stopColor={'var(--color-ppr-red-light)'} />
          </linearGradient>
        </defs>
        {deBars.map((deBar, i) => {
          let isTopBar = false
          let isBottomBar = false
          if (i === 0) {
            isBottomBar = true
          }
          if (i === deBars.length - 1) {
            isTopBar = true
          }
          return (
            <Bar
              key={deBar.dataKey}
              shape={
                <RoundedShapeBar
                  borderRadius={barBorderRadius}
                  isTopBar={isTopBar}
                  isBottomBar={isBottomBar}
                />
              }
              dataKey={deBar.dataKey}
              stackId="a"
              name={deBar.label}
              fill={deBar.fill}
            >
              {!hideLabels && (
                <LabelList
                  dx={labelMargin}
                  width={labelWidth}
                  position={'right'}
                  content={
                    <BarLabel
                      percentFill={deBar.fillLabel}
                      percent={data[deBar.dataKey]}
                      description={deBar.label}
                    />
                  }
                />
              )}
            </Bar>
          )
        })}
        {showTotal && (
          <XAxis
            fontWeight={300}
            fontSize={12}
            tickMargin={10}
            padding={0}
            height={40}
            tick={false}
            // tickFormatter={(v) => {
            //   const totalAmount = dataByYears[v].total_amount
            //   return totalAmount === null
            //     ? ''
            //     : amountFormatter.format(totalAmount)
            // }}
            axisLine={false}
            tickLine={false}
            dataKey="year"
          >
            <Label
              fontSize={12}
              fontWeight={300}
              fontFamily={'Ideal Sans'}
              strokeWidth={0.5}
              fill="var(--black-chart)"
              dy={0}
              dx={40}
              value={`Number of projects rated: ${
                data.at_risk + data.on_track + data.for_attention
              }`}
            />
          </XAxis>
        )}
      </BarChart>
    </ResponsiveContainer>
  )
}
