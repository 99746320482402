import { keyBy, uniqBy } from 'lodash'
import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import { Fragment, useMemo } from 'react'
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import ProductsAreaChart from '../../components/ProductsAreaChart'
import { SlimYearsAxis } from '../../components/ProductsAreaChart/ProductsAreaChart'
import Select from '../../components/Select'
import Spinner from '../../components/Spinner'
import {
  COLOR_CLUSTER_WORD,
  FUND_ORDERS,
  LABELS_AND_COLORS_GROUP,
  MODALITY_ORDERS,
  OPTIONS_CLUSTER,
  SECTORS_ORDER,
} from '../../consts'
import { useDomain } from '../../Domain'
import { usePortfolioInfoTrend } from '../../hooks/portfolioInfo'
import mapOrder from '../../utils'
import LayoutPortfolioInfo from './LayoutPortofolioInfo'
import PreviewMode from '../../components/PreviewMode'

export default function TrendGroupCountry() {
  const navigate = useNavigate()
  const { group, country } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const qsParams = {
    cluster: searchParams.get('cluster') ?? '',
    preview: searchParams.get('preview') ?? '',
  }
  const apiParams = useMemoCompare({
    ...qsParams,
    group,
    country,
  })

  const apiParamsGroup = useMemoCompare({
    group,
    preview: qsParams.preview,
  })

  const [{ data: dataGroup }] = usePortfolioInfoTrend(apiParamsGroup)
  const [{ data, dataParams, loading }] = usePortfolioInfoTrend(apiParams)

  const domain = useDomain()
  const countriesByCode = useMemo(
    () => keyBy(domain.countries_data, 'code'),
    [domain.countries_data]
  )

  const countriesToUse = useMemo(() => {
    if (!dataGroup || dataGroup.length === 0) {
      return []
    }
    const distinctCountries = uniqBy(
      dataGroup[0].countries,
      'project__country'
    ).map((c) => ({
      value: c.project__country,
      label: countriesByCode[c.project__country].name,
    }))
    return [{ value: '', label: 'All countries' }].concat(distinctCountries)
  }, [countriesByCode, dataGroup])

  return (
    <LayoutPortfolioInfo
      showSectorTooltip={apiParams.cluster === 'project__sector_code'}
      filters={
        <>
          <Select
            onChange={(e) => {
              navigate({
                pathname: `../trend/${group}/${e.value}`,
                search: createSearchParams(qsParams).toString(),
              })
            }}
            className="mb-3"
            options={countriesToUse}
            isDisabled={!dataGroup}
            value={country}
            label={'Country selected'}
          />
          <Select
            onChange={(e) => {
              setSearchParams({
                ...qsParams,
                cluster: e.value,
              })
            }}
            defaultValue={''}
            value={apiParams.cluster}
            label={'Cluster By'}
            options={OPTIONS_CLUSTER}
          />
        </>
      }
    >
      {!data && loading && (
        <div className="h-100 w-100 d-flex align-items-center justify-content-cente">
          <Spinner />
        </div>
      )}
      <div className="h-100 w-100 position-relative">
        {data &&
          (dataParams.cluster ? (
            <div className="h-100 ps-5 pe-5 pb-5 w-100 d-flex position-relative flex-column">
              <SlimYearsAxis
                years={domain.years}
                syncId="asian"
                showYearsAxis
                // marginLeft={80}
                marginRightExtra={7}
              />
              <div className="asian-card-without-cursor h-100 position-relative w-100 overflow-y-scroll">
                {data.map((clusterData) => (
                  <div className="w-100" key={clusterData.project__country}>
                    {mapOrder(
                      clusterData.cluster,
                      dataParams.cluster === 'product_type'
                        ? MODALITY_ORDERS
                        : dataParams.cluster === 'fund_mnemonic'
                        ? FUND_ORDERS
                        : SECTORS_ORDER,
                      dataParams.cluster === 'product_type'
                        ? 'product_type'
                        : dataParams.cluster === 'fund_mnemonic'
                        ? 'fund_mnemonic'
                        : 'project__sector_code'
                    ).map((data, i) => (
                      <div
                        style={{ height: 200 }}
                        key={data[dataParams.cluster]}
                      >
                        <ProductsAreaChart
                          title={
                            countriesByCode[clusterData.project__country]?.name
                          }
                          titleFixed
                          widthTitle={'75%'}
                          colorStrong={`var(--color-${
                            COLOR_CLUSTER_WORD[dataParams.cluster]
                          }-solid-${data[dataParams.cluster].toLowerCase()})`}
                          colorLight={`var(--color-${
                            COLOR_CLUSTER_WORD[dataParams.cluster]
                          }-light-${data[dataParams.cluster].toLowerCase()})`}
                          gradient={data[dataParams.cluster].toLowerCase()}
                          showClusterName={true}
                          clusterName={data[dataParams.cluster]}
                          showTitle={i === 0}
                          showYearsTicks={false}
                          syncId={'asian'}
                          cluster={dataParams.cluster}
                          cardBackground={false}
                          data={data.years}
                          years={domain.years}
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            data.map((groupData, i) => (
              <div className="ps-5 w-100 h-100 pe-5 pb-5" key={groupData.group}>
                <ProductsAreaChart
                  key={groupData.project__country}
                  title={countriesByCode[groupData.project__country]?.name}
                  colorStrong={LABELS_AND_COLORS_GROUP[group].color1}
                  colorLight={LABELS_AND_COLORS_GROUP[group].color2}
                  gradient={LABELS_AND_COLORS_GROUP[group].gradient}
                  showYearsAxis={i === 0}
                  showYearsTicks={true}
                  country={groupData.project__country}
                  group={groupData.group}
                  syncId={'asian'}
                  cardBackground={true}
                  cursor={false}
                  data={groupData.years}
                  years={domain.years}
                />
              </div>
            ))
          ))}
      </div>
      {qsParams.preview && <PreviewMode />}
    </LayoutPortfolioInfo>
  )
}
