import { keyBy, uniqBy } from 'lodash'
import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import { useMemo } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Select from '../../components/Select'
import Spinner from '../../components/Spinner'
import TetrisBarChart from '../../components/TetrisBarChart'
import { useDomain } from '../../Domain'
import { usePPR, usePPRAndIssues } from '../../hooks/ppr'
import LayoutPPR from './LayoutPPR'
import PreviewMode from '../../components/PreviewMode'

export default function PPRTrendGroupCountry() {
  const navigate = useNavigate()
  const { group, country } = useParams()
  const [searchParams] = useSearchParams()
  const preview = searchParams.get('preview') ?? ''
  const apiParams = useMemoCompare({
    group,
    country,
    preview,
  })

  const apiParamsGroup = useMemoCompare({
    group,
    preview,
  })

  const [{ data }] = usePPRAndIssues(apiParams)
  const [{ data: dataGroup }] = usePPR(apiParamsGroup)

  const domain = useDomain()
  const countriesByCode = useMemo(
    () => keyBy(domain.countries_data, 'code'),
    [domain.countries_data]
  )
  const countryName = countriesByCode[country]?.name

  const countriesToUse = useMemo(() => {
    if (!dataGroup || dataGroup.length === 0) {
      return []
    }
    const distinctCountries = uniqBy(dataGroup[0].countries, 'country').map(
      (c) => ({
        value: c.country,
        label: countriesByCode[c.country].name,
      })
    )
    return [{ value: '', label: 'All countries' }].concat(distinctCountries)
  }, [countriesByCode, dataGroup])

  return (
    <LayoutPPR
      filters={
        <>
          <Select
            onChange={(e) => {
              navigate({
                pathname: `../trend/${group}/${e.value}`,
              })
            }}
            isDisabled={!dataGroup}
            options={countriesToUse}
            value={country}
            label={'Country selected'}
          />
        </>
      }
    >
      {!data && (
        <div className="h-100 w-100 d-flex align-items-center justify-content-cente">
          <Spinner />
        </div>
      )}
      <div className="ps-5 percentage-info">
        Percentages may not add up due to rounding.
      </div>
      {data && data.ppr.length > 0 && data.issues && (
        <TetrisBarChart
          countryName={countryName}
          data={data.ppr[0].years}
          issuesData={data.issues}
        />
      )}
      {apiParams.preview && <PreviewMode />}
    </LayoutPPR>
  )
}
