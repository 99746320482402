import { orderBy } from 'lodash'
import { Link, useSearchParams } from 'react-router-dom'
// import AutoTipModal from '../../components/AutoTipModal'
import BarChartStacked from '../../components/BarChartStacked'
import Select from '../../components/Select'
import Spinner from '../../components/Spinner'
import { useDomain } from '../../Domain'
import { useYearsOptions } from '../../hooks/options'
import { usePPRSnapshot } from '../../hooks/ppr'
import LayoutPPR from './LayoutPPR'
import { useMemo } from 'react'
import PreviewMode from '../../components/PreviewMode'
// import imageSource from './assets/tip-snapshot.svg'

export default function PPRSnapshotIndex() {
  const domain = useDomain()
  const [searchParams, setSearchParams] = useSearchParams()
  const year = Number(searchParams.get('year') ?? Math.max(...domain.years))
  const preview = searchParams.get('preview') ?? ''
  const yearsOptions = orderBy(useYearsOptions(), 'value', 'desc')
  const previewFilter = useMemo(() => {
    return {
      preview,
    }
  }, [preview])
  const [{ data, loading }] = usePPRSnapshot(year, previewFilter)

  return (
    <LayoutPPR
      filters={
        <>
          <Select
            onChange={(e) => {
              setSearchParams({
                preview,
                year: e.value,
              })
            }}
            value={year}
            yearSelect
            options={yearsOptions}
          />
        </>
      }
    >
      <div className="mb-1 ps-5 percentage-info">
        Percentages may not add up due to rounding.
      </div>
      {!data && loading && (
        <div className="h-100 w-100 d-flex align-items-center justify-content-cente">
          <Spinner />
        </div>
      )}
      {data && (
        <div className="h-100 w-100 row pt-2 ps-5 pe-4 pb-5">
          {data.map((group, i) => (
            <Link
              to={group.group+`?year=${year}&preview=${preview}`}
              className="col-md h-100 no-link"
              key={group.group}
            >
              <div className="asian-card h-100 d-flex flex-column">
                <div className="text-center text-uppercase mt-1">
                  {group.group}
                </div>
                <div className="flex-1 d-flex justify-content-center">
                  <BarChartStacked data={group} />
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}
      {preview && <PreviewMode />}
      {/* <AutoTipModal type={'ppr-snapshot'} imageSource={imageSource} /> */}
    </LayoutPPR>
  )
}
