import { groupBy, keyBy, orderBy, uniqBy } from 'lodash'
import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { ChartCirclePack } from '../../components/ChartCirclePack'
import Select from '../../components/Select'
import {
  FUND_ORDERS,
  MODALITY_ORDERS,
  OPTIONS_CLUSTER,
  SECTORS_ORDER,
} from '../../consts'
import { useDomain } from '../../Domain'
import { useYearsOptions } from '../../hooks/options'
import {
  usePortfolioInfoSnapshot,
  usePortfolioInfoSnapshotMinMaxCountry,
} from '../../hooks/portfolioInfo'
import LayoutPortfolioInfo from './LayoutPortofolioInfo'
import arrow from './assets/arrow.svg'
import { ProjectState } from '../../hooks/project'
import { deps, useRj } from 'react-rocketjump'
import ProjectDetail from '../../components/ProjectDetail'
import Spinner from '../../components/Spinner'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import goDown from './assets/go-down.svg'
import iconSectors from '../../components/assets/info-sectors.svg'
import TooltipSectors from '../../components/TooltipSectors'
import PreviewMode from '../../components/PreviewMode'

function TableProducts({ data, openProject, setHoverProject, hoverProject }) {
  const [showTooltip, setShowTooltip] = useState(false)
  return (
    <table
      className="table table-snapshot"
      onMouseLeave={() => setHoverProject(null)}
    >
      <thead>
        <tr style={{ height: 40 }}>
          <th width={270}>
            Title <br />
          </th>
          <th>
            <div className="d-flex">
              Sector{' '}
              <img
                onMouseLeave={() => setShowTooltip(false)}
                onMouseEnter={() => setShowTooltip(true)}
                className="ms-2"
                src={iconSectors}
                alt="Sectors"
              />{' '}
              {showTooltip && <TooltipSectors />}
              <br />
            </div>
          </th>
          <th>
            Modality <br />
          </th>
          <th>
            Fund <br />
          </th>
          <th>
            Net amount <br />
            <span className="unit-table-snapshot">($ Million)</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {data[0].countries.map((product, i) => (
          <Fragment key={i}>
            <tr
              style={{
                opacity: hoverProject
                  ? hoverProject === product.pk
                    ? 1
                    : 0.2
                  : 1,
              }}
              className="position-relative"
            >
              <td onMouseEnter={() => setHoverProject(product.pk)}>
                <div>
                  <div className="text-ellipsis">
                    {product.project__project_title}
                  </div>
                  <div>({product.project__project_code})</div>
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      backgroundColor: `var(--color-sectors-solid-${product.project__sector_code.toLowerCase()})`,
                    }}
                    className={'CircleSector'}
                  ></div>
                  <div className="ms-2">{product.project__sector_code}</div>
                </div>
              </td>
              <td>{product.product_type}</td>
              <td>{product.fund_mnemonic}</td>
              <td>{product.amount.toFixed(2)}</td>
              {hoverProject && hoverProject === product.pk && (
                <div
                  className="position-absolute pointer"
                  style={{
                    left: 0,
                    top: 0,
                    width: 'max-content',
                    background: '#E2EDF6',
                    transition: '0.2s linear all',
                    borderRadius: 10,
                  }}
                  onClick={() => {
                    openProject.open(product.project__pk)
                  }}
                >
                  <div className="d-flex align-items-center">
                    <div>
                      <div>{product.project__project_title}</div>
                      <div>({product.project__project_code})</div>
                    </div>
                    <div>
                      <img src={arrow} alt="Go to project" />
                    </div>
                  </div>
                </div>
              )}
            </tr>
          </Fragment>
        ))}
      </tbody>
    </table>
  )
}

export default function SnaphostGroupCountry() {
  const { group, country } = useParams()
  const [project, openProject] = useModalTrigger(null)
  const navigate = useNavigate()
  const domain = useDomain()
  const [hoverProject, setHoverProject] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const qsParams = {
    cluster: searchParams.get('cluster') ?? '',
    preview: searchParams.get('preview') ?? '',
  }
  const apiParams = useMemoCompare({
    ...qsParams,
    country,
    group,
  })

  const apiParamsGroup = useMemoCompare({
    group,
    preview: qsParams.preview,
  })

  const boxBubbles = useRef()

  const yearsOptions = orderBy(useYearsOptions(), 'value', 'desc')
  const year = Number(searchParams.get('year') ?? Math.max(...domain.years))

  const [{ data, dataParams, loading }] = usePortfolioInfoSnapshot(
    year,
    apiParams
  )
  const [{ data: dataGroup }] = usePortfolioInfoSnapshot(year, apiParamsGroup)

  const [{ data: dataMinMax }] = usePortfolioInfoSnapshotMinMaxCountry(
    year,
    apiParams
  )

  const countriesByCode = useMemo(
    () => keyBy(domain.countries_data, 'code'),
    [domain.countries_data]
  )

  const dataCluster = useMemo(() => {
    if (dataParams && dataParams.cluster && data && data.length > 0) {
      return groupBy(data[0].countries, dataParams.cluster)
    }
    return []
  }, [data, dataParams])

  const countriesToUse = useMemo(() => {
    if (!dataGroup || dataGroup.length === 0) {
      return []
    }
    const distinctCountries = uniqBy(
      dataGroup[0].countries,
      'project__country'
    ).map((c) => ({
      value: c.project__country,
      label: countriesByCode[c.project__country].name,
    }))
    return [{ value: '', label: 'All countries' }].concat(distinctCountries)
  }, [countriesByCode, dataGroup])

  const id = useMemo(() => {
    if (project.value) {
      return project.value.project__pk
    }
    return null
  }, [project])

  const [{ data: projectData }, { run }] = useRj(ProjectState, [deps.maybe(id)])

  const [isScrollable, setScrollable] = useState(false)

  useEffect(() => {
    if (project.value) {
      run(project.value)
    }
  }, [project, run])

  const computeColor = useCallback((d) => {
    return `url('#color_${d.data.project__sector_code.toLowerCase()}')`
  }, [])

  const computeValue = useCallback((node) => {
    return node.total_amount
  }, [])

  useEffect(() => {
    if (data) {
      function updateState() {
        const el = boxBubbles.current
        el &&
          data &&
          setScrollable(
            el.scrollHeight - 10 > el.getBoundingClientRect().height
          )
      }

      updateState()

      window.addEventListener('resize', updateState)
      return () => window.removeEventListener('resize', updateState)
    }
  }, [data])

  const clusterOrder =
    dataParams &&
    (dataParams.cluster === 'product_type'
      ? MODALITY_ORDERS
      : dataParams.cluster === 'fund_mnemonic'
      ? FUND_ORDERS
      : SECTORS_ORDER)

  return (
    <LayoutPortfolioInfo
      showSectorTooltip={apiParams.cluster === 'project__sector_code'}
      filters={
        <>
          <Select
            onChange={(e) => {
              setSearchParams({
                ...qsParams,
                year: e.value,
              })
            }}
            className="mb-3"
            value={year}
            yearSelect
            options={yearsOptions}
          />
          <Select
            onChange={(e) => {
              navigate({
                pathname: `../snapshot/${group}/${e.value}`,
                search: createSearchParams(qsParams).toString(),
              })
            }}
            className="mb-3"
            options={countriesToUse}
            isDisabled={!dataGroup}
            value={country}
            label={'Country selected'}
          />
          <Select
            onChange={(e) => {
              setSearchParams({
                ...qsParams,
                year,
                cluster: e.value,
              })
            }}
            value={qsParams.cluster}
            label={'Cluster By'}
            options={OPTIONS_CLUSTER}
          />
        </>
      }
    >
      <div className="h-100 row">
        <div
          style={{ height: 'calc(100vh - 120px)', overflowY: 'auto' }}
          className="col-md-4 bubblesBox mt-5"
          ref={boxBubbles}
        >
          <div
            style={{
              width: '23%',
              zIndex: 10,
              backgroundColor: 'var(--color-basic-lightblue-1)',
            }}
            className="text-center position-absolute ps-4 fw-350 fs-14 mb-5"
          >
            <div style={{ borderBottom: '1px solid rgba(13, 142, 202, 0.6)' }}>
              {countriesByCode[country].name}
            </div>
          </div>

          {!data && loading && (
            <div className="h-100 w-100 d-flex align-items-center justify-content-cente">
              <Spinner />
            </div>
          )}
          <div style={{ marginTop: 70 }}>
            {data &&
              dataMinMax &&
              data.length > 0 &&
              (!dataParams.cluster ? (
                <div>
                  <ChartCirclePack
                    data={data[0].countries}
                    computeColor={computeColor}
                    showTooltip={false}
                    computeValue={computeValue}
                    country={apiParams.country}
                    year={year}
                    height={400}
                    hoverProject={hoverProject}
                    openProject={openProject}
                    marginLeft={0}
                    marginRight={0}
                    marginTop={20}
                    marginBottom={20}
                    dataMin={dataMinMax.min_amount}
                    dataMax={dataMinMax.max_amount}
                  />
                </div>
              ) : (
                Object.keys(dataCluster)
                  .sort(function (a, b) {
                    return clusterOrder.indexOf(a) - clusterOrder.indexOf(b)
                  })
                  .map((clusterMode, i) => (
                    <div key={i} className="d-flex align-items-center">
                      <div className="ms-3 tooltip-cluster">
                        {clusterMode === 'Others' &&
                        dataParams.cluster === 'fund_mnemonic'
                          ? 'Others / Cofinancing'
                          : clusterMode}
                      </div>
                      <ChartCirclePack
                        showTooltip={false}
                        data={dataCluster[clusterMode]}
                        computeColor={computeColor}
                        computeValue={computeValue}
                        height={320}
                        year={year}
                        openProject={openProject}
                        cluster={dataParams.cluster}
                        marginLeft={0}
                        country={apiParams.country}
                        hoverProject={hoverProject}
                        marginRight={0}
                        marginTop={20}
                        paddingCircles={6}
                        marginBottom={20}
                        dataMin={dataMinMax.min_amount}
                        dataMax={dataMinMax.max_amount}
                      />
                    </div>
                  ))
              ))}
            {isScrollable && boxBubbles.current && (
              <div
                onClick={() => {
                  boxBubbles.current.scrollBy({
                    top: 200,
                    behavior: 'smooth',
                  })
                }}
                className="position-absolute pointer d-flex justify-content-center"
                style={{ bottom: 20, width: '26%' }}
              >
                <img src={goDown} alt="Go down" />
              </div>
            )}
          </div>
        </div>
        <div
          style={{ width: '65.66666667%', height: '90%' }}
          className="col-md-8 mb-2 pb-3 pe-1 overflow-y-scroll asian-card-without-cursor mt-5"
        >
          <div className="fs-10 ps-4 mb-4 pt-2">LIST OF PRODUCTS</div>
          {data && data.length > 0 && (
            <TableProducts
              setHoverProject={setHoverProject}
              hoverProject={hoverProject}
              data={data}
              openProject={openProject}
            />
          )}
        </div>
        {project.value && !loading && (
          <ProjectDetail
            fromWhere={`Portfolio information > ${group.toUpperCase()} > ${
              countriesByCode[country].name
            }`}
            isOpen={project.isOpen}
            toggle={() => openProject.toggle()}
            project={project.value}
            year={year}
            onExited={() => openProject.onClosed()}
          />
        )}
      </div>
      {qsParams.preview && <PreviewMode />}
    </LayoutPortfolioInfo>
  )
}
