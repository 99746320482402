import AutoTSMenuLayout from '../../components/AutoTSMenuLayout'

/**
 * @param {{
 *  children: React.ReactNode
 *  filters: React.ReactNode
 * }} props
 */
export default function LayoutPortfolioInfo({ children, ...props }) {
  return (
    <AutoTSMenuLayout
      subtitle="Portfolio amount and number of products"
      breadcrumbLabel="Portfolio distribution"
      info='portfolio-distribution'
      {...props}
    >
      {children}
    </AutoTSMenuLayout>
  )
}
