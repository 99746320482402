import * as d3 from 'd3'
import { keyBy } from 'lodash'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useEffect, useMemo, useRef, useState } from 'react'
import GradientClusters from '../../components/GradientsClusters'
import ProjectDetail from '../../components/ProjectDetail'
import { useDomain } from '../../Domain'

const HEIGHT = 120

export const amountFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
})

export function titleCase(str) {
  var splitStr = str.toLowerCase().split(' ')
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  // Directly return the joined string
  return splitStr.join(' ')
}

export default function CountryBeeSwarm({
  country,
  displayAxis,
  extent,
  data,
  radiusExtent,
  indicativePipelines = false,
  group,
}) {
  const refBeeswarm = useRef()
  const redrawRef = useRef()
  const divTooltip = useRef()

  const domain = useDomain()

  const [year, setYear] = useState(null)

  const countriesByCode = useMemo(
    () => keyBy(domain.countries_data, 'code'),
    [domain.countries_data]
  )

  const [project, openProject] = useModalTrigger()

  useEffect(() => {
    const handler = () => {
      redrawRef.current?.()
    }
    window.addEventListener('resize', handler)
    return () => window.removeEventListener('resize', handler)
  }, [])

  const customTicks = useMemo(() => {
    const customTicks = []
    let iterDate = new Date(extent[0])
    iterDate.setMonth(6)
    while (iterDate < extent[1]) {
      customTicks.push(new Date(iterDate))
      iterDate.setFullYear(iterDate.getFullYear() + 1)
    }
    return customTicks
  }, [extent])

  useEffect(() => {
    let height = HEIGHT
    let margin = {
      top: 0,
      right: 40,
      bottom: 34,
      left: 80,
    }

    // Data structure describing chart scales
    let Scales = {
      time: 'scaleTime',
    }

    // Data structure describing volume of displayed data
    let Count = {
      year: indicativePipelines ? 'year_date' : 'closing_date',
    }

    // Data structure describing legend fields value
    let Legend = {
      amount: 'amount',
    }

    let chartState = {}

    chartState.year = Count.year
    chartState.scale = Scales.time
    chartState.legend = Legend.amount

    // Colors used for circles depending on continent
    let colors = d3
      .scaleOrdinal()
      .domain([
        'anr',
        'others',
        'psm',
        'hlt',
        'ind',
        'wus',
        'ene',
        'edu',
        'ict',
        'fin',
        'xxx',
        'tra',
      ])
      .range([
        "url('#color_anr')",
        "url('#color_others_sectors')",
        "url('#color_psm')",
        "url('#color_hlt')",
        "url('#color_ind')",
        "url('#color_wus')",
        "url('#color_ene')",
        "url('#color_edu')",
        "url('#color_fin')",
        "url('#color_ict')",
        "url('#color_xxx')",
        "url('#color_tra')",
      ])

    // const svgOld = select(refBeeswarm.current)
    // svgOld.selectAll('*').remove() // add this before the append.

    let svg = d3.select(refBeeswarm.current).attr('height', height)

    if (displayAxis) {
      svg
        .append('g')
        .attr('class', 'x axis')
        .attr('transform', 'translate(0,0)')
    }

    // Create line that connects circle and X axis
    let xLine = svg
      .append('line')
      .attr('stroke', 'rgb(96,125,139)')
      .attr('stroke-dasharray', '1,2')

    // Create tooltip div and make it invisible
    let tooltip = d3
      .select(divTooltip.current)
      .attr('class', 'tooltip')
      .style('opacity', 0)

    let dataSet = data

    // Set chart domain max value to the highest total value in data set

    let scaleAmount = d3.scaleLinear().range([6, 27])

    scaleAmount.domain(radiusExtent)

    // Trigger filter function whenever checkbox is ticked/unticked
    d3.selectAll('input').on('change', filter)

    function redraw() {
      const width = window.innerWidth - 20

      svg.attr('width', width)

      const countryNode = svg.selectAll('.country').data([country])
      countryNode
        .enter()
        .append('text')
        .classed('country', true)
        .text(country)
        .attr('x', 20)
        .attr('font-weight', 400)
        .attr('font-family', 'var(--ideal-sans)')
        .attr('y', HEIGHT / 2)
        .attr('text-anchor', 'left')
        .attr('alignment-baseline', 'central')

      // Set scale type based on button clicked
      let xScale = d3.scaleTime().range([margin.left, width - margin.right])

      xScale.domain(extent)

      const tickNodes = svg
        .selectAll('.ticks')
        .data(displayAxis ? customTicks : [], (d) => d.getFullYear().toString())

      tickNodes.exit().remove()

      tickNodes
        .enter()
        .append('text')
        .classed('ticks', true)
        .classed('text-style-kpi-s-light', true)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'hanging')
        .text((node) => node.getFullYear())
        .merge(tickNodes)
        .transition()
        .duration(1000)
        .attr('x', (node) => xScale(node))
        .attr('y', 0)

      const grid = svg
        .selectAll('.grid')
        .data(customTicks, (datum) => datum.getFullYear().toString())

      grid.exit().remove()

      grid
        .enter()
        .append('line')
        .classed('grid', true)
        .attr('x1', (node) => xScale(node))
        .attr('x2', (node) => xScale(node))
        .attr('y1', displayAxis ? 20 : 0)
        .attr('y2', 200)
        .attr('stroke', '#A4A9C1')
        .attr('stroke-dasharray', '2px')
        .merge(grid)
        .transition()
        .duration(1000)
        .attr('y1', displayAxis ? 20 : 0)
        .attr('x1', (node) => xScale(node))
        .attr('x2', (node) => xScale(node))

      const countryAxis = svg.selectAll('.country-axis-x').data([country])
      countryAxis
        .enter()
        .append('line')
        .classed('country-axis-x', true)
        .attr('stroke', 'rgba(13, 142, 202, 0.6)')
        .attr('x1', margin.left)
        .attr('x2', width - margin.right)
        .attr('y1', HEIGHT / 2)
        .attr('y2', HEIGHT / 2)
        .merge(countryAxis)
        .transition()
        .duration(1000)
        .attr('x1', margin.left)
        .attr('x2', width - margin.right)
        .attr('y1', HEIGHT / 2)
        .attr('y2', HEIGHT / 2)

      // Create simulation with specified dataset
      let simulation = d3
        .forceSimulation(dataSet)
        // Apply positioning force to push nodes towards desired position along X axis
        .force(
          'x',
          d3
            .forceX(function (d) {
              // Mapping of values from total/perCapita column of dataset to range of SVG chart (<margin.left, margin.right>)
              return xScale(new Date(d[chartState.year])) // This is the desired position
            })
            .strength(0.1)
        ) // Increase velocity
        .force('y', d3.forceY(height - HEIGHT / 2)) // // Apply positioning force to push nodes towards center along Y axis
        .force(
          'collide',
          d3.forceCollide((node) => scaleAmount(node.amount) + 1)
        ) // Apply collision force with radius of 9 - keeps nodes centers 9 pixels apart
        .stop() // Stop simulation from starting automatically

      // Manually run simulation
      for (let i = 0; i < dataSet.length; ++i) {
        simulation.tick(20)
      }

      // Create country circles
      let countriesCircles = svg
        .selectAll('.products')
        .data(dataSet, function (d) {
          return d.id
        })

      countriesCircles
        .exit()
        .transition()
        .duration(1000)
        .attr('opacity', 0)
        .remove()

      const className = indicativePipelines ? 'products' : 'products pointer'

      countriesCircles
        .enter()
        .append('circle')
        .classed(className, true)
        .attr('cx', function (d) {
          return d.x
        })
        .attr('cy', function (d) {
          return d.y
        })
        .attr('opacity', 0)
        .attr('r', function (d) {
          return scaleAmount(d.amount)
        })
        .merge(countriesCircles)
        .transition()
        .duration(1000)
        .attr('cx', function (d) {
          return d.x
        })
        .attr('cy', function (d) {
          return d.y
        })
        .attr('opacity', function (d) {
          return d.visible ? 1 : 0
        })
        .attr('fill', function (d) {
          return indicativePipelines
            ? colors(d.sector_code.toLowerCase())
            : d.project_data.update_status === 'Closed'
            ? colors(d.project_data.sector_code.toLowerCase())
            : 'transparent'
        })
        .attr('stroke', function (d) {
          return indicativePipelines
            ? 'transparent'
            : d.project_data.update_status === 'Cancelled'
            ? colors(d.project_data.sector_code.toLowerCase())
            : 'transparent'
        })

      // Show tooltip when hovering over circle (data for respective country)

      d3.selectAll('.products')
        .on('click', function (d) {
          openProject.open(d.target.__data__.project_data.id)
          setYear(d.target.__data__.year)
        })
        .on('mousemove', function (d) {
          d3.selectAll('circle').attr('fill-opacity', 0.2)
          d3.selectAll('circle').attr('stroke-opacity', 0.2)
          d3.select(this).attr('fill-opacity', 1)
          d3.select(this).attr('stroke-opacity', 1)

          if (!indicativePipelines) {
            const targetId = d.target.__data__.id.toString()
            if (tooltip.attr('data-target-id') !== targetId) {
              tooltip
                .html(
                  `<span class='fw-350 text-color-basic-dark-grey'> ${
                    countriesByCode[d.target.__data__.project_data.country].name
                  } (${
                    d.target.__data__.fcas && d.target.__data__.sids
                      ? 'FCAS / SIDS'
                      : d.target.__data__.sids
                      ? 'SIDS'
                      : 'FCAS'
                  })</span><br />
              <div class='project-title-tooltip'>
              ${titleCase(d.target.__data__.project_data.project_title)}</div>
              <div class='mt-3 d-flex'>
              <div class='fw-300 fs-12 width-100 me-3'>Sector:</div><div class='width-100 fw-350'>${
                d.target.__data__.project_data.sector
              }</div></div>
                <div class='mt-3 d-flex'>
                <div class='fw-300 fs-12 width-100 me-3'>Amount ($ mn ):</div><div class='fw-350'>${amountFormatter.format(
                  d.target.__data__.amount
                )}</div></div>
                <div class='mt-3 d-flex'>
                <div class='fw-300 fs-12 width-100 me-3'>Closing Date:</div><div class='fw-350'>${
                  d.target.__data__.closing_date
                }</div></div>
                <div class='mt-3 d-flex'>
                <div class='fw-300 fs-12 width-100 me-3'>Product:</div><div class='fw-350'>${
                  d.target.__data__.product_type
                }</div></div>
                <div class='text-end mt-3 fw-325 text-primary-asian'>
                Click to learn more
                </div>
                          `
                )
                .style('opacity', 1)
                .style('width', 180)
                .style('display', 'block')
                .style('position', 'absolute')
                .style('padding', '10px')
                .style('background', 'white')
                .style('border-radius', '10px')
                .style('box-shadow', '0px 0px 8px rgba(13, 142, 202, 0.22)')
                .style('border', '0.5px solid #EEF1FA')
                .attr('data-target-id', targetId)
            }

            let x = d.clientX + 10
            let y = d.clientY + 10

            const txp = d.clientX > window.innerWidth / 2 ? '-100%' : '0px'
            const typ = d.clientY > window.innerHeight / 2 ? '-100%' : '0px'

            const txf = d.clientX > window.innerWidth / 2 ? '-20px' : '0px'
            const tyf = d.clientY > window.innerHeight / 2 ? '-20px' : '0px'

            tooltip
              .style('left', x + 'px')
              .style('top', y + 'px')
              .style(
                'transform',
                `translate(${txp}, ${typ}) translate(${txf}, ${tyf})`
              )
          } else {
            const targetId = d.target.__data__.id.toString()
            if (tooltip.attr('data-target-id') !== targetId) {
              tooltip
                .html(
                  `<span class='fw-350 text-color-basic-dark-grey'>${
                    countriesByCode[d.target.__data__.country].name
                  } (${
                    d.target.__data__.fcas && d.target.__data__.sids
                      ? 'FCAS / SIDS'
                      : d.target.__data__.sids
                      ? 'SIDS'
                      : 'FCAS'
                  })</span><br />
      <div class='project-title-tooltip'>
      ${titleCase(d.target.__data__.project_name)}</div>
      <div class='mt-3 d-flex'>
      <div class='fw-300 fs-12 width-100 me-3'>Sector:</div><div class='width-100 fw-350'>${
        d.target.__data__.sector
      }</div></div>
        <div class='mt-3 d-flex'>
        <div class='fw-300 fs-12 width-100 me-3'>Amount ($ mn ):</div><div class='fw-350'> ${amountFormatter.format(
          d.target.__data__.amount
        )}</div></div>`
                )
                .style('opacity', 1)
                .style('width', 200)
                .style('display', 'block')
                .style('position', 'absolute')
                .style('padding', '10px')
                .style('background', 'white')
                .style('border-radius', '10px')
                .style('box-shadow', '0px 0px 8px rgba(13, 142, 202, 0.22)')
                .style('border', '0.5px solid #EEF1FA')
            }

            let x = d.clientX + 10
            let y = d.clientY + 10

            const txp = d.clientX > window.innerWidth / 2 ? '-100%' : '0px'
            const typ = d.clientY > window.innerHeight / 2 ? '-100%' : '0px'

            const txf = d.clientX > window.innerWidth / 2 ? '-20px' : '0px'
            const tyf = d.clientY > window.innerHeight / 2 ? '-20px' : '0px'

            tooltip
              .style('left', x + 'px')
              .style('top', y + 'px')
              .style(
                'transform',
                `translate(${txp}, ${typ}) translate(${txf}, ${tyf})`
              )
          }
          xLine
            .attr('x1', d3.select(this).attr('cx'))
            .attr('y1', d3.select(this).attr('cy'))
            .attr('y2', height - margin.bottom)
            .attr('x2', d3.select(this).attr('cx'))
            .attr('opacity', 1)
        })
        .on('mouseout', function (_) {
          tooltip.style('opacity', 0)
          d3.selectAll('circle').attr('fill-opacity', 1)
          d3.selectAll('circle').attr('stroke-opacity', 1)
          tooltip.style('display', 'none').attr('data-target-id', '')
          xLine.attr('opacity', 0)
        })
    }
    redrawRef.current = redraw

    redraw()

    // Filter data based on which checkboxes are ticked
    function filter() {
      function getCheckedBoxes(checkboxName) {
        let checkboxes = d3.selectAll(checkboxName).nodes()
        let checkboxesChecked = []
        for (let i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].checked) {
            checkboxesChecked.push(checkboxes[i].defaultValue)
          }
        }
        return checkboxesChecked.length > 0 ? checkboxesChecked : null
      }

      let checkedBoxes = getCheckedBoxes('.continent')

      let newData = []

      if (checkedBoxes == null) {
        dataSet = newData
        redraw()
        return
      }

      for (let i = 0; i < checkedBoxes.length; i++) {
        let newArray = data.filter(function (d) {
          return d.continent === checkedBoxes[i]
        })
        Array.prototype.push.apply(newData, newArray)
      }

      dataSet = newData
      redraw()
    }
  }, [
    countriesByCode,
    country,
    customTicks,
    data,
    radiusExtent,
    displayAxis,
    extent,
    group,
    indicativePipelines,
    openProject,
  ])

  return (
    <>
      <svg ref={refBeeswarm}>
        <defs>
          <GradientClusters />
        </defs>
      </svg>
      <div ref={divTooltip}></div>
      {project.value && !indicativePipelines && (
        <ProjectDetail
          isOpen={project.isOpen}
          fromWhere={'Completed products'}
          year={year}
          toggle={() => {
            setYear(null)
            openProject.toggle()
          }}
          onExited={() => openProject.onClosed()}
          project={project.value}
        />
      )}
    </>
  )
}
