import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import { Link, useSearchParams } from 'react-router-dom'
import Select from '../../components/Select'
import { OPTIONS_CLUSTER_DC } from '../../consts'
import { useDCSnapshot, useDCTrend } from '../../hooks/disbandcontract'
import LayoutDisbursmentContract from './LayoutDisbursmentContract'
import CardDCSnapshot from '../../components/CardDCSnapshot'
import CardDCSnapshotCluster from '../../components/CardDCSnapshotCluster'
import { useYearsOptions } from '../../hooks/options'
import { useDomain } from '../../Domain'
import { Fragment, useMemo } from 'react'
import { orderBy } from 'lodash'
import PreviewMode from '../../components/PreviewMode'
// import AutoTipModal from '../../components/AutoTipModal'
// import imageSource from './assets/tip-snapshot.svg'

export default function DCSnapshotIndex() {
  const domain = useDomain()
  const [searchParams, setSearchParams] = useSearchParams()
  const apiParams = useMemoCompare({
    cluster: searchParams.get('cluster') ?? '',
    preview: searchParams.get('preview') ?? '',
  })

  const yearsOptions = orderBy(useYearsOptions(), 'value', 'desc')
  const year = Number(searchParams.get('year') ?? Math.max(...domain.years))

  const [{ data, dataParams }] = useDCSnapshot(year, apiParams)
  const [{ data: trend }] = useDCTrend()

  const allImports = useMemo(() => {
    if (!trend) {
      return []
    }
    let all = []
    trend.map((t) => t.years.map((d) => all.push(d.total_approved)))
    return all
  }, [trend])

  return (
    <LayoutDisbursmentContract
      filters={
        <>
          <Select
            onChange={(e) => {
              setSearchParams({
                ...apiParams,
                year: e.value,
              })
            }}
            className={'mb-4'}
            value={year}
            options={yearsOptions}
            yearSelect
          />
          <Select
            onChange={(e) => {
              setSearchParams({
                ...apiParams,
                cluster: e.value,
              })
            }}
            value={apiParams.cluster}
            label={'Cluster By'}
            options={OPTIONS_CLUSTER_DC}
          />
        </>
      }
    >
      <div className="w-100 h-100 row ps-5 pt-3 pb-5 pr-3">
        {data &&
          (dataParams.cluster
            ? data.map((group, i) => (
                <Link
                  key={group.group}
                  className="no-link col-md h-100"
                  to={group.group+'?year='+year+'&preview='+apiParams.preview}
                >
                  <div className="h-100 asian-card me-4 overflow-y-scroll">
                    <div className="title-card text-center text-uppercase">
                      {group.group}
                    </div>
                    <div className="subtitle">Amount in $ million</div>
                    {group.cluster.map((clusterData, k) => (
                      <Fragment key={clusterData.product_type}>
                        <CardDCSnapshotCluster
                          noBorder={k === group.cluster.length - 1}
                          singleData={clusterData}
                          allData={group.cluster}
                          title={clusterData.product_type}
                        />
                        {k !== group.cluster.length - 1 && (
                          <hr
                            style={{ color: '#E2EDF6', opacity: 1 }}
                            className="my-0 mx-3"
                          />
                        )}
                      </Fragment>
                    ))}
                  </div>
                </Link>
              ))
            : data.map((group, i) => (
                <Link
                  key={group.group}
                  className="no-link col-md h-100"
                  to={group.group+'?year='+year+'&preview='+apiParams.preview}
                >
                  <CardDCSnapshot
                    titleUpp
                    allImports={allImports}
                    singleData={group}
                    title={group.group}
                    key={group.group}
                  />
                </Link>
              )))}
      </div>
      {/* <AutoTipModal type={'dc-snapshot'} imageSource={imageSource} /> */}
      {apiParams.preview && (
        <PreviewMode />
      )}
    </LayoutDisbursmentContract>
  )
}
