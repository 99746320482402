import { keyBy, difference, uniq, orderBy } from 'lodash'
import { PureComponent, useMemo } from 'react'
import {
  Area,
  Dot,
  AreaChart,
  ComposedChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'
import classNames from 'classnames'
import { useLastImportDate } from '../../LastImport'

const MARGIN_LEFT = 40
const MARGIN_RIGHT = 50

export const amountFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
})

function LineTick(props) {
  const { x, y } = props
  return (
    <line
      x1={x}
      y1={y - 20}
      x2={x}
      y2={y + 12}
      stroke="var(--color-light-blue-grey)"
    />
  )
}

class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, value, strokeColor, isDisbursment } = this.props

    return (
      <text
        x={x}
        y={y}
        dy={isDisbursment ? 18 : -12}
        fill={strokeColor}
        fontSize={12}
        textAnchor="middle"
      >
        {value ? amountFormatter.format(parseFloat(value)) : ''}
      </text>
    )
  }
}

export function SlimYearsAxis({ showYearsAxis, years, syncId }) {
  const data = useMemo(
    () =>
      orderBy(
        years.map((year) => ({ year })),
        'year'
      ),
    [years]
  )
  const date = useLastImportDate()
  return (
    <div style={{ height: showYearsAxis ? 32 : 20, width: '100%' }}>
      <ResponsiveContainer>
        <ComposedChart
          className={
            showYearsAxis ? 'top-years-lines-with-numbers' : 'top-years-lines'
          }
          data={data}
          syncId={syncId}
          margin={{
            bottom: 0,
            top: 0,
            right: MARGIN_RIGHT,
            left: MARGIN_LEFT,
          }}
        >
          <YAxis axisLine={false} tickLine={false} />
          <XAxis
            tickSize={13}
            fontSize={12}
            stroke="var(--color-light-blue-grey)"
            tickMargin={showYearsAxis ? undefined : 0}
            tick={showYearsAxis ? undefined : <LineTick />}
            axisLine={false}
            tickLine={showYearsAxis}
            xAxisId={'years'}
            orientation="top"
            dataKey={(x) =>
              x.year
                .toString()
                .replace(
                  date.last_year,
                  `${date.last_year} ${date.last_quarter}`
                )
            }
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}

export default function DisbusmentContractAreaChart({
  data,
  years,
  syncId,
  title,
  showTitle = true,
  showYearsAxis = false,
  showYearsTicks = true,
  showClusterName = false,
  cardBackground = false,
  titleFixed = false,
  cursor = true,
  country = null,
  group = null,
  clusterName = null,
  colorStrong = 'var(--color-primary-solid)',
  colorLight = 'var(--color-sectors-solid-ene)',
}) {
  const chartData = useMemo(() => {
    const missYears = difference(years, uniq(data.map((d) => d.year)))
    let filledData = data.concat(
      missYears.map((year) => ({
        year,
        total_disbursment: null,
        total_approved: null,
      }))
    )
    filledData = orderBy(filledData, 'year', 'asc')
    return filledData
  }, [data, years])

  const showDotsYears = useMemo(() => {
    const dots = []
    chartData.forEach((datum, i) => {
      let show = true
      if (i > 0 && chartData[i - 1].total_amount !== null) {
        show = false
      } else if (
        i < chartData.length - 1 &&
        chartData[i + 1].total_amount !== null
      ) {
        show = false
      }
      if (show) {
        dots.push(datum.year)
      }
    })
    return dots
  }, [chartData])

  const dataByYears = useMemo(() => keyBy(chartData, 'year'), [chartData])

  return (
    <div className="h-100 w-100 d-flex flex-column" style={{ zIndex: 2 }}>
      {showYearsTicks && (
        <SlimYearsAxis
          years={years}
          syncId={syncId}
          showYearsAxis={showYearsAxis}
        />
      )}
      <div
        className={classNames(
          'flex-1 w-100 d-flex flex-column position-relative',
          {
            'asian-card': cardBackground && cursor,
            'asian-card-without-cursor': cardBackground && !cursor,
          }
        )}
      >
        {title && showTitle && titleFixed && (
          <div
            style={{
              zIndex: 10,
              width: 'calc(100% - 6rem)',
              backgroundColor: 'var(--color-basic-white-100)',
            }}
            className="fw-350 position-fixed text-dark-grey-basic text-center"
          >
            {title}
          </div>
        )}
        {title && showTitle && !titleFixed && (
          <div className="fw-350 text-color-basic-dark-grey fs-14 text-center">
            {title}
          </div>
        )}
        {title && showTitle && country === 'LAO' && group === 'fcas' && (
          <div className="fw-300 text-dark-grey-basic mt-1 text-center">
            Lao PDR has been classified as FCAS beginning Q4 2020. Portfolio
            information covers 2021 and onwards.
          </div>
        )}
        {title && showTitle && country === 'NIU' && group === 'sids' && (
          <div className="fw-300 text-dark-grey-basic mt-1 text-center">
            Niue has been a part of SIDS beginning 2019. Portfolio information
            covers 2019 and onwards.
          </div>
        )}
        {title && showTitle && country === 'PAL' && group === 'fcas' && (
          <div className="fw-300 text-dark-grey-basic mt-1 text-center">
            Palau has been classified as FCAS beginning 2023. Portfolio
            information covers 2023 and onwards.
          </div>
        )}
        <div
          className="position-absolute w-100 d-flex justify-content-center legend-dc"
          style={{ bottom: 10 }}
        >
          <div className="me-5 d-flex align-items-center">
            <div
              className="me-2"
              style={{ height: 2, width: 16, background: colorStrong }}
            ></div>
            Approved amount
          </div>
          <div className="d-flex align-items-center">
            <div
              className="me-2"
              style={{ height: 2, width: 16, background: colorLight }}
            ></div>
            Cumulative disbursement
          </div>
        </div>
        <div className="flex-1 w-100">
          <ResponsiveContainer className={'pointer'}>
            <AreaChart
              margin={{
                right: MARGIN_RIGHT,
                left: MARGIN_LEFT,
                bottom: 40,
                top: 40,
              }}
              data={chartData}
              syncId={syncId}
            >
              <XAxis
                stroke={colorStrong}
                fontWeight="400"
                fontSize={12}
                padding={0}
                height={0}
                tickMargin={10}
                tick={true}
                tickFormatter={(v) => {
                  const totalApproved = dataByYears[v].total_approved
                  return totalApproved === null
                    ? ''
                    : amountFormatter.format(totalApproved)
                }}
                axisLine={false}
                tickLine={false}
                dataKey="year"
              />
              <XAxis
                fontWeight="400"
                fontSize={12}
                tickMargin={8}
                stroke={colorLight}
                padding={0}
                height={0}
                tick={false}
                tickFormatter={(v) => {
                  const totalDisbursment = dataByYears[v].total_disbursment
                  return totalDisbursment === null
                    ? ''
                    : amountFormatter.format(totalDisbursment)
                }}
                axisLine={false}
                tickLine={false}
                xAxisId={'count'}
                dataKey="year"
              />

              <YAxis
                fontSize={12}
                stroke="var(--grey-3)"
                fontWeight={300}
                tickCount={2}
                axisLine={false}
                tickLine={false}
              >
                {showClusterName && clusterName && (
                  <Label
                    fontSize={14}
                    fontWeight={350}
                    value={clusterName}
                    fill="var(--color-basic-dark-grey)"
                    dx={-30 + clusterName.length * 2 - 10}
                    strokeWidth={0.5}
                    dy={-95}
                    position="bottom"
                  />
                )}
                {/* <Label
                  fontWeight={300}
                  fontSize={12}
                  value={'Approved amount'}
                  // strokeWidth={0.5}
                  fill="var(--black-chart)"
                  dy={0}
                  dx={-50}
                  position="center"
                /> */}
                <Label
                  fontWeight={300}
                  fontSize={12}
                  value={'($ million)'}
                  // strokeWidth={0.5}
                  fill="var(--black-chart)"
                  dy={showClusterName && clusterName ? -65 : -110}
                  dx={-20}
                  position="center"
                />
              </YAxis>
              <CartesianGrid strokeDasharray="3 3" horizontal={false} />

              <Area
                isAnimationActive={true}
                type="monotone"
                label={
                  <CustomizedLabel isDisbursment strokeColor={colorLight} />
                }
                dataKey="total_disbursment"
                dot={(props) => {
                  if (showDotsYears.includes(props.payload.year)) {
                    return <Dot {...props} className="recharts-area-dot" />
                  }
                  return <Dot {...props} className="recharts-area-dot" />
                }}
                stroke={colorLight}
                fill={colorLight}
                fillOpacity={0.2}
                strokeWidth={3}
              />
              <Area
                isAnimationActive={true}
                type="monotone"
                label={<CustomizedLabel strokeColor={colorStrong} />}
                dataKey="total_approved"
                dot={(props) => {
                  if (showDotsYears.includes(props.payload.year)) {
                    return <Dot {...props} className="recharts-area-dot" />
                  }
                  return <Dot {...props} className="recharts-area-dot" />
                }}
                stroke={colorStrong}
                fill={colorStrong}
                fillOpacity={0.2}
                strokeWidth={3}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  )
}
