import { keyBy, orderBy, uniqBy } from 'lodash'
import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import { useMemo } from 'react'
import {
  createSearchParams,
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import BarChartStacked from '../../components/BarChartStacked'
import Select from '../../components/Select'
import Spinner from '../../components/Spinner'
import { OPTIONS_GROUP_PPR, COUNTRIES_PPR_NO_SHOW } from '../../consts'
import { useDomain } from '../../Domain'
import { useYearsOptions } from '../../hooks/options'
import { usePPRSnapshot } from '../../hooks/ppr'
import { LimitedChipsContainer } from '../Search/Search'
import LayoutPPR from './LayoutPPR'
import PreviewMode from '../../components/PreviewMode'

const ALL_COUNTRIES_VALUES = ['']

export default function PPRSnapshotGroup() {
  const { group } = useParams()
  const navigate = useNavigate()
  const domain = useDomain()
  const [searchParams, setSearchParams] = useSearchParams()
  const qsParams = {
    countries: searchParams.getAll('countries') ?? [],
    year: searchParams.get('year') ?? Math.max(...domain.years),
    preview: searchParams.get('preview') ?? '',
  }
  const apiParams = useMemoCompare({
    ...qsParams,
    group,
    preview: qsParams.preview,
  })

  const uiCountries =
    qsParams.countries.length > 0 ? qsParams.countries : ALL_COUNTRIES_VALUES
  const yearsOptions = orderBy(useYearsOptions(), 'value', 'desc')
  const year = Number(searchParams.get('year') ?? Math.max(...domain.years))
  const [{ data, dataParams, loading }] = usePPRSnapshot(year, apiParams)

  const countriesByCode = useMemo(
    () => keyBy(domain.countries_data, 'code'),
    [domain.countries_data]
  )

  const countriesToUse = useMemo(() => {
    if (!data || data.length === 0) {
      return []
    }
    const distinctCountries = uniqBy(data[0].countries, 'country').map((c) => ({
      value: c.country,
      label: countriesByCode[c.country].name,
    }))
    if (year === 2021) {
      return distinctCountries.filter(
        (d) => !COUNTRIES_PPR_NO_SHOW.includes(d.value)
      )
    }
    return distinctCountries
  }, [countriesByCode, data, year])

  const dataToUse = useMemo(() => {
    if (data && data.length > 0) {
      return data[0].countries.filter((c) =>
        apiParams.countries.length > 0
          ? apiParams.countries.includes(c.country)
          : c
      )
    }
    return []
  }, [apiParams.countries, data])

  const dataFinal = useMemo(() => {
    if (data && dataToUse && dataToUse.length > 0) {
      if (year === 2021) {
        return dataToUse.filter(
          (d) => !COUNTRIES_PPR_NO_SHOW.includes(d.country)
        )
      } else {
        return dataToUse
      }
    }
  }, [dataToUse, year, data])

  return (
    <LayoutPPR
      filters={
        <>
          <Select
            onChange={(e) => {
              setSearchParams({
                ...qsParams,
                year: e.value,
              })
            }}
            className="mb-3"
            value={year}
            yearSelect
            options={yearsOptions}
          />
          <Select
            onChange={(e) => {
              navigate({
                pathname: `../snapshot/${e.value}`,
                search: createSearchParams({ year }).toString(),
              })
            }}
            className="mb-3"
            value={group}
            label={'Classification'}
            options={OPTIONS_GROUP_PPR}
            defaultValue={'fcas'}
          />
          <Select
            onChange={(e) => {
              if (
                dataParams.countries.length !== 0 &&
                e.filter((d) => d.value === '').length > 0
              ) {
                setSearchParams({
                  ...qsParams,
                  countries: [],
                })
              } else {
                setSearchParams({
                  ...qsParams,
                  countries: e.map((c) => c.value).filter(Boolean),
                })
              }
            }}
            closeMenuOnSelect={false}
            id="Countries"
            placeholder={'All countries'}
            components={
              dataParams &&
              dataParams.countries &&
              dataParams.countries.length > 0 && {
                MultiValue: LimitedChipsContainer,
              }
            }
            isMulti
            value={uiCountries}
            isDisabled={!data}
            options={[{ value: '', label: 'All countries' }].concat(
              countriesToUse
            )}
            label={'Countries comparison'}
          />
        </>
      }
    >
      <div className="d-flex flex-column overflow-y-scroll h-100">
        {!data && loading && (
          <div className="h-100 w-100 d-flex align-items-center justify-content-center">
            <Spinner />
          </div>
        )}
        <div className="mb-1 ps-5 percentage-info">
          Percentages may not add up due to rounding.
        </div>
        <div className="d-flex h-100  pt-3 ps-5 pe-4 pb-3">
          {data &&
            dataToUse &&
            dataFinal &&
            dataFinal.map((countryData, k) => (
              <div
                key={countryData.country}
                className="asian-card d-flex flex-column min-width-card me-3 h-100"
              >
                <div className="text-center mt-1">
                  {countriesByCode[countryData.country].name}
                </div>
                <div className="h-100  ps-5">
                  <Link
                    className="no-link"
                    to={
                      countryData.country +
                      `?year=${year}&preview=${qsParams.preview}`
                    }
                  >
                    <BarChartStacked data={countryData} />
                  </Link>
                </div>
              </div>
            ))}
        </div>
      </div>
      {qsParams.preview && <PreviewMode />}
    </LayoutPPR>
  )
}
